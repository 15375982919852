import React, { useState, useEffect } from "react";
import { Modal, Form, Button, InputGroup, Table } from "react-bootstrap";
import { mesaj, TContainer, Ganimated, XvalidationSchema } from "../helpers/generic";
import {
    BsFillXSquareFill,
    BsFillCheckSquareFill,
    BsFillPencilFill,
    BsFillPersonLinesFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";
import * as yup from "yup";
//import { Animated } from "react-animated-css";

const CalismaDonemleri = (props) => {
    const initialInputDatas = [
        {
            varsayilan: 1,
            baslik: "",
            yil: "",
            donem: "",
            aciklama: "Açıklama",
            kilit: 1,
            ogretmen: 1,
            calismaDonemiKitlensin: 1,
            ogretmenlerDersPrograminiGorebilsin: 0,
            varsayilanCalismaDonemiOlsun: 1,
        },
    ];
    const [show, setShow] = useState(false);
    const [btnDuzenle, setBtnDuzenle] = useState(-1);
    const handleClose = () => {
        setShow(false);
        setBtnDuzenle(-1);
    };

    const handleShow = () => {
        setInputDatas(initialInputDatas);
        setShow(true);
    };
  
    const [inputDatas, setInputDatas] = useState(initialInputDatas);
    const liste = secureLocalStorage.getItem("CalismaDonemleri");
    const [data, setData] = useState(liste ?? []);

    const validationSchema = yup.object().shape({
        baslik: yup
            .string()
            .min(5, "Başlık en az 5 karakter olmalıdır")
            .required("Zorunlu alan"),
        donem: yup.string().min(1, "Dönem seçilmek zorundadır.").required(),
        yil: yup.string().min(1, "Yıl seçilmek zorundadır.").required(),
    });
    const kaydet = () => {
        validationSchema.isValid(inputDatas[0]).then((valid) => {
            //console.log(valid); // => true
            if (valid) {
                let li1 = structuredClone(data ? data : []);
                li1.push(inputDatas[0]);
                setData(li1);
                secureLocalStorage.setItem("CalismaDonemleri", li1);
                mesaj("success", "Yeni kayıt eklendi!");
                props.fnVal("CalismaDonemleri", 1);
                setShow(false);
            }
        });
        validationSchema.validate(inputDatas[0]).catch(function (err) {
            mesaj("error", err.errors[0]);
        });
    };
    const eventHandle = (propname, val, index = 0, element) => {
        let dat = structuredClone(inputDatas);
        dat[index][propname] = val ? val : "";
        setInputDatas(dat);
    };
    const duzenleHandle = (key) => {
        setBtnDuzenle(key);
        const duzenle = structuredClone(data);
        setInputDatas([duzenle[key]]);
        setShow(true);
    };
    const guncelleHandle = (key) => {
        validationSchema.isValid(inputDatas[0]).then((valid) => {
            //console.log(valid); // => true
            if (valid) {
                const guncelle = structuredClone(data);
                guncelle[key] = inputDatas[0];
                //console.log(guncelle);
                setData(guncelle);
                setShow(false);
                mesaj("success", "Güncelleme işlemi başarılı!");
            }
        });
        validationSchema.validate(inputDatas[0]).catch(function (err) {
            mesaj("error", err.errors[0]);
        });

    };
    const silHandle = (key) => {
        const sil = structuredClone(data);
        sil.splice(key, 1);
        setData(sil);
        secureLocalStorage.setItem("CalismaDonemleri", sil);
        mesaj("success", "Silme işlemi başarılı");
    };
    useEffect(() => {
        setData(liste);
        setInputDatas(initialInputDatas);
    }, []);
    useEffect(() => {
        props.fnVal("CalismaDonemleri", data?.length);
        //props.fnVal("CalismaDonemleri", (data?.length > 0 || data != null) ? 1 : 0);
    }, [data]); 
    return (
        <>
            {/* <h2 className="card-header">Çalışma Dönemleri</h2> */}
            <div className="card-body bg-light">
                <hr></hr>
                <Ganimated
                    isVisible={true}
                    className={data?.length > 0 ? "ff " : "infinite"}
                >
                    <Button variant="primary" onClick={handleShow}>
                        Yeni çalışma dönemi ekle
                    </Button>
               
                </Ganimated>
                <hr></hr>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Varsayılan</th>
                            <th>Başlık</th>
                            <th>Yıl</th>
                            <th>Dönem</th>
                            <th>Kilit</th>
                            <th>Öğretmen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data ? (
                            data.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <span
                                                className="handCursor"
                                                data-tip="Sil"
                                                onClick={() => {
                                                    window.confirm("Silmek istediğinizden emin misiniz?")
                                                        ? silHandle(index)
                                                        : mesaj("success", "İptal ettiniz");
                                                }}
                                            >
                                                <BsFillXSquareFill color="red" />
                                            </span>
                                            <span>&nbsp;</span>
                                            <span
                                                className="handCursor"
                                                data-tip="Düzenle"
                                                onClick={() => {
                                                    //setBtnGuncelle(index);
                                                    duzenleHandle(index);
                                                }}
                                            >
                                                <BsFillPencilFill color="green" />
                                            </span>
                                            <span>&nbsp;</span>
                                            <span
                                                className="handCursor"
                                                data-tip="Kullanıcı Yetkileri"
                                                onClick={() => {
                                                    alert("Düzenlemek istediğinizden emin misiniz?");
                                                }}
                                            >
                                                <BsFillPersonLinesFill color="black" />
                                            </span>
                                        </td>
                                        <td>
                                            {el.varsayilan === 1 ? (
                                                <BsFillCheckSquareFill color="green" />
                                            ) : (
                                                <BsFillCheckSquareFill color="red" />
                                            )}
                                        </td>
                                        <td>{el.baslik}</td>
                                        <td>{el.yil}</td>
                                        <td>{el.donem}</td>
                                        <td>
                                            {el.kilit === 1 ? (
                                                <BsFillCheckSquareFill color="green" />
                                            ) : (
                                                <BsFillCheckSquareFill color="red" />
                                            )}
                                        </td>
                                        <td>
                                            {el.ogretmen === 1 ? (
                                                <BsFillCheckSquareFill color="green" />
                                            ) : (
                                                <BsFillCheckSquareFill color="red" />
                                            )}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td>Kayıt Ekleyiniz</td>
                                <td>Kayıt Ekleyiniz</td>
                                <td>Kayıt Ekleyiniz</td>
                                <td>Kayıt Ekleyiniz</td>
                                <td>Kayıt Ekleyiniz</td>
                                <td>Kayıt Ekleyiniz</td>
                                <td>Kayıt Ekleyiniz</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <hr></hr>
            </div>

            {/* <TContainer /> */}

            <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yeni çalışma dönemi ekleme</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="plsValid was-validated">
                        <Form.Group className="mb-3">
                            <Form.Label>Yılı</Form.Label>
                            <br />
                            <select
                                className="form-control"
                                required
                                onChange={(e) => {
                                    eventHandle("yil", e.target.value);
                                }}
                                defaultValue={inputDatas[0].yil}
                            >
                                <option value="">Seçin</option>
                                <option value="2024-2025">2024-2025</option>
                                <option value="2023-2024">2023-2024</option>
                                <option value="2022-2023">2022-2023</option>
                                <option value="2021-2022">2021-2022</option>
                            </select>
                            <span className="validation-error">
                                {/* {formik.errors.donem } */}
                            </span>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Dönemi</Form.Label>
                            <br />

                            <select
                                name="donem"
                                required
                                className="form-control"
                                onChange={(e) => {
                                    eventHandle("donem", e.target.value);
                                }}
                                defaultValue={inputDatas[0].donem}
                            >
                                <option value="">Seçin</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                            <span className="validation-error">
                                {/* {formik.errors.donem ? "Zorunlu alan" : ""} */}
                            </span>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Başlık</Form.Label>
                            <InputGroup className="mb-3">
                                <Button
                                    variant="primary"
                                    onClick={(e) => {
                                        let str =
                                            inputDatas[0]?.yil +
                                            " Eğitim Öğretim Yılı " +
                                            inputDatas[0]?.donem +
                                            ". Dönemi";
                                        //formik.values.baslik = str;
                                        eventHandle("baslik", str);
                                    }}
                                >
                                    Başlık Oluştur
                                </Button>
                                <input
                                    className="form-control"
                                    id="baslik"
                                    name="baslik"
                                    required
                                    type="text"
                                    placeholder="Başlık oluşturun"
                                    onChange={(e) => {
                                        eventHandle("baslik", e.target.value);
                                    }}
                                    value={inputDatas[0]?.baslik}
                                // onChange={(e)=>{formik.handleChange(e);console.log(formik.dirty);}}
                                //value={formik.values.baslik}
                                />
                                <span className="validation-error">
                                    {/* {formik.errors.baslik } */}
                                </span>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Açıklama</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                onChange={(e) => {
                                    eventHandle("aciklama", e.target.value);
                                }}
                                value={inputDatas[0]?.aciklama}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <InputGroup className="mb-3">
                                <Form.Check
                                    id="cdk"
                                    type="checkbox"
                                    onChange={(e) => {
                                        eventHandle(
                                            "calismaDonemiKitlensin",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                    defaultChecked={
                                        inputDatas[0]?.calismaDonemiKitlensin === 1 ? true : false
                                    }
                                />
                                <Form.Label htmlFor="cdk">
                                    <span>&nbsp;</span>Çalışma dönemi kilitlensin
                                </Form.Label>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <InputGroup className="mb-3">
                                <Form.Check
                                    id="odpg"
                                    type="checkbox"
                                    onChange={(e) => {
                                        eventHandle(
                                            "ogretmenlerDersPrograminiGorebilsin",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                    defaultChecked={
                                        inputDatas[0]?.ogretmenlerDersPrograminiGorebilsin === 1
                                            ? true
                                            : false
                                    }
                                />
                                <Form.Label htmlFor="odpg">
                                    <span>&nbsp;</span>Öğretmenler ders programını görebilir
                                </Form.Label>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <InputGroup className="mb-3">
                                <Form.Check
                                    id="vcd"
                                    type="checkbox"
                                    onChange={(e) => {
                                        eventHandle(
                                            "varsayilanCalismaDonemiOlsun",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                    defaultChecked={
                                        inputDatas[0]?.varsayilanCalismaDonemiOlsun === 1
                                            ? true
                                            : false
                                    }
                                />
                                <Form.Label htmlFor="vcd">
                                    <span>&nbsp;</span>Varsayılan çalışma dönemi olsun
                                </Form.Label>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {btnDuzenle === -1 ? (
                        ""
                    ) : (
                        <Button
                            variant="primary"
                            onClick={() => guncelleHandle(btnDuzenle)}
                        >
                            Güncelle
                        </Button>
                    )}
                    <Button variant="primary" onClick={kaydet}>
                        Kaydet
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Kapat
                    </Button>
                </Modal.Footer>
            </Modal>
         
        </>
    );
};
export default CalismaDonemleri;
