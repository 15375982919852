import React, { useState, useEffect } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import secureLocalStorage from "react-secure-storage";
import * as yup from "yup";
export default function DersGunleri(props) {
  const localListe = [
    {
      chk: 1,
      gun: "Pazartesi",
      kisaad: "Pzt",
    },
    {
      chk: 1,
      gun: "Salı",
      kisaad: "Sal",
    },
    {
      chk: 1,
      gun: "Çarsamba",
      kisaad: "Çrş",
    },
    {
      chk: 1,
      gun: "Persembe",
      kisaad: "Prş",
    },
    {
      chk: 1,
      gun: "Cuma",
      kisaad: "Cum",
    },
    {
      chk: 0,
      gun: "Cumartesi",
      kisaad: "Cmt",
    },
    {
      chk: 0,
      gun: "Pazar",
      kisaad: "Pzr",
    },
  ];

  const liste = secureLocalStorage.getItem("DersGunleri");
  const [data, setData] = useState(liste ?? localListe ?? []);

  useEffect(() => {
    setData(liste ?? localListe);
    props.fnVal("DersGunleri", liste == null ? 0 : 1);
  }, []);
  const evetnHandle = (i, e, propname) => {
    let list = data;
    ////console.log(list, i, e, propname);

    if (propname === "chk") {
      list[i][propname] = e.target.checked ? 1 : 0;
    } else {
      if (propname === "grup") {
        list[i][propname] = e;
      } else {
        list[i][propname] = e.target.value;
        //////console.log("değil", propname, list);
      }
    }
    setData(list);
    ////console.log(data);
  };
  const kaydet = () => {
    let li1 = structuredClone(data ? data : []);
    secureLocalStorage.setItem("DersGunleri", li1);
    props.fnVal("DersGunleri", 1);
    mesaj("success", "Başarıyla kaydedildi!");
  };
  return (
    <>
      {/* <TContainer />  */}
      <div className="card-body bg-light">
        <hr></hr>
        <Form className="was-validated">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Aktif</th>
                <th>Gün</th>
                <th>Kısa Ad</th>
              </tr>
            </thead>
            <tbody>
              {data ? (
                data.map((el, index) => {
                  return (
                    <tr key={index} >
                      <td>
                        <Form.Check
                          type="checkbox"
                          id={"chk-" + index}
                          defaultChecked={el.chk === 1 ? "checked" : ""}
                          onClick={(e) => evetnHandle(index, e, "chk")}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          defaultValue={el.gun}
                          onChange={(e) => evetnHandle(index, e, "gun")}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          defaultValue={el.kisaad}
                          onChange={(e) => evetnHandle(index, e, "kisaad")}
                        ></Form.Control>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr></tr>
              )}
            </tbody>
          </Table>
        </Form>
        <Ganimated
          className={data?.length > 0 ? "ff " : "infinite"}>
          <Button
            variant="primary"
            onClick={(e) => {
              kaydet(e);
            }}
          >
            Kaydet
          </Button>
        </Ganimated>

        <hr></hr>
      </div>
    </>
  );
}
