import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import {
  BsFillXSquareFill,
  BsFillCheckSquareFill,
  BsFillPencilFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import * as yup from "yup";

const Ogretmenler = (props) => {
  const bransListe = [
    {
      bransKod: "",
      brans: "Seçin",
    },
    {
      bransKod: "ADA",
      brans: "ADALET",
    },
    {
      bransKod: "BİLTEK",
      brans: "BİLİŞİM TEKNOLOJİLERİ",
    },
    {
      bransKod: "ALM",
      brans: "ALMANCA",
    },
  ];
  const liste = [
    {
      ad: "",
      soyad: "",
      listeAdi: "",
      brans: "",
      tc: "",
    },
  ];
  const localListe = secureLocalStorage.getItem("Ogretmenler");

  const [show, setShow] = useState(false);
  const [btnDuzenle, setBtnDuzenle] = useState(-1);
  const [modalListe, setModalListe] = useState(liste);
  const [data, setData] = useState(localListe ?? []);
  const [selectedOption, setSelectedOption] = useState(liste[0].bransKod);

  const handleShow = () => {
    setModalListe(liste);
    setBtnDuzenle(-1);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setBtnDuzenle(-1);
  };
  const silHandle = (key) => {
    const sil = structuredClone(data);
    sil.splice(key, 1);
    setData(sil);
    secureLocalStorage.setItem("Ogretmenler", sil);
    mesaj("success", "Silme işlemi başarılı");
  };
  const eventHandle = (propname, val, index = 0, element) => {
    let dat = structuredClone(modalListe);
    dat[index][propname] = val ? val : "";
    setModalListe(dat);
  };
  const kaydet = () => {
    let dat = structuredClone(data);
    dat.push(modalListe[0]);
    setData(dat);
    secureLocalStorage.setItem("Ogretmenler", dat);
    setShow(false);
    mesaj("success", "Yeni kayıt eklendi!");
  };
  const duzenleHandle = (key) => {
    setBtnDuzenle(key);
    const duzenle = structuredClone(data);
    setModalListe([duzenle[key]]);
    setShow(true);
  };
  const guncelleHandle = (key) => {
    setBtnDuzenle(key);
    const guncelle = structuredClone(data);
    guncelle[key] = modalListe[0];
    setData(guncelle);
    secureLocalStorage.setItem("Ogretmenler", guncelle);
    setShow(false);
    mesaj("success", "Güncelleme işlemi başarılı!");
  };

  useEffect(() => {
    //setData(localListe ?? []);
    //secureLocalStorage.setItem("Ogretmenler", data);
    props.fnVal("Ogretmenler",data?.length);
  }, [data]);

  return (
    <>
      {/* <pre>{JSON.stringify(localListe??[])}</pre> */}
      {/* <TContainer /> */}
      {/* <h2 className="card-header">Öğretmenler</h2> */}
      <div className="card-body bg-light">
        <hr></hr>
        <Ganimated className={data?.length > 0 ? "ff " : "infinite"}>
        <Button variant="primary" onClick={handleShow}>
          Öğretmen Ekle
        </Button>
        {/* <Button variant="primary" onClick={(e)=>{console.log(data,data.length)}}>
          test
        </Button> */}
        </Ganimated>
       
        <hr></hr>
  
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Sıra No</th>
              <th>Adı Soyadı</th>
              <th>Liste Adı</th>
              <th>Branşı</th>
              <th>Tc Kimlik</th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              data.map((el, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span
                        className="handCursor"
                        data-tip="Sil"
                        onClick={() => {
                          window.confirm("Silmek istediğinizden emin misiniz?")
                            ? silHandle(index)
                            : mesaj("success", "İptal ettiniz");
                        }}
                      >
                        <BsFillXSquareFill color="red" />
                      </span>
                      <span>&nbsp;</span>
                      <span
                        className="handCursor"
                        data-tip="Düzenle"
                        onClick={() => {
                          //setShow(true);
                          //setModalListe([el])
                          duzenleHandle(index);
                        }}
                      >
                        <BsFillPencilFill color="green" />
                      </span>
                    </td>
                    <td>{index + 1}</td>
                    <td>
                      {(el.ad + " " + el.soyad)
                        .toString()
                        .toLocaleUpperCase("TR")}
                    </td>
                    <td>{el.listeAdi}</td>
                    <td>{el.brans}</td>
                    <td>{el.tc}</td>
                  </tr>
                );
              })
            ) : (
              <tr>yükleniyor</tr>
            )}
          </tbody>
        </Table>
        {data ? data?.length + " adet eklediniz." : ""}
        <hr></hr>

      </div>

      <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Öğretmen Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="was-validated">
            <Form.Group className="mb-3">
              <Form.Label>Adı</Form.Label>
              <Form.Control
                type="text"
                required
                value={modalListe[0].ad}
                onChange={(e) => eventHandle("ad", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Soyadı</Form.Label>
              <Form.Control
                type="text"
                required
                value={modalListe[0].soyad}
                onChange={(e) => eventHandle("soyad", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Liste Adı</Form.Label>
              <Form.Control
                type="text"
                required
                value={modalListe[0].listeAdi}
                onChange={(e) => eventHandle("listeAdi", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tc Kimlik</Form.Label>
              <Form.Control
                type="number"
                required
                value={modalListe[0].tc}
                onChange={(e) => eventHandle("tc", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Branş</Form.Label>
              <br />
              <select
                className="form-control"
                value={selectedOption}
                required
                onChange={(e) => {
                  eventHandle("brans", e.target.value);
                  //setSelectedOption(e.target.value)
                }}
                defaultValue={modalListe[0].brans}
              >
                {
                  //bransListe ? (
                  bransListe.map((o, index) => {
                    return (
                      <option key={index} value={o.bransKod}>
                        {o.brans}
                      </option>
                    );
                  })
                }
              </select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {btnDuzenle === -1 ? (
            ""
          ) : (
            <Button
              variant="primary"
              onClick={(e) => guncelleHandle(btnDuzenle)}
            >
              Güncelle
            </Button>
          )}
          <Button variant="primary" onClick={kaydet}>
            Kaydet
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
          <br />
        </Modal.Footer>
      </Modal>
      {props.children}
    </>
  );
};
export default Ogretmenler;
