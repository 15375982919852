import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

export default function SifremiUnuttum() {

  return (
    <main className="main" id="top">
      <div class="container" data-layout="container">
        <div class="row flex-center min-vh-100 py-6 text-center">
          <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4"><a class="d-flex flex-center mb-4" href="/"><img class="me-2" src="../../../assets/img/icons/spot-illustrations/falcon.png" alt="" width="58" /><span class="font-sans-serif fw-bolder fs-5 d-inline-block">Dağıtmatik</span></a>
            <div class="card">
              <div class="card-body p-4 p-sm-5">
                <h5 class="mb-0">Şifreni mi unuttun?</h5><small>E-posta adresinizi girin. Bağlantı linkini yollayacağız.</small>
                <form class="mt-4"><input class="form-control" type="email" placeholder="Email address" />
                  <div class="mb-3"></div><button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Bağlantıyı gönder</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
