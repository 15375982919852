import React, { useState, useRef, useEffect, createContext } from "react";

import { Modal, Form, Button, InputGroup, Table } from "react-bootstrap";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import {
  BsFillXSquareFill,
  BsFillCheckSquareFill,
  BsFillFileEarmarkBreakFill,
  BsBank,
  BsCardChecklist,
  BsHouseFill,
  BsTsunami,
  BsArchiveFill,
  BsBuilding,
  BsFillCalendar2DayFill,
  BsFillClockFill,
  BsFillMenuButtonWideFill,
  BsPersonCircle,
  BsFillAlarmFill,
  BsFillFileEarmarkEaselFill,
  BsBarChartSteps,
  BsFillHouseFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";
import * as yup from "yup";

import CalismaDonemleri from "./CalismaDonemleri";
import RaporBilgileri from "./RaporBilgileri";
import DersGunleri from "./DersGunleri";
import DersSaatleri from "./DersSaatleri";
import Branslar from "./Branslar";
import Ogretmenler from "./Ogretmenler";
import SiniflarVeSubeler from "./SiniflarVeSubeler";
import BinalarveDerslikler from "./BinalarveDerslikler";
import DersHavuzu from "./DersHavuzu";
import DersAtamalari from "./DersAtamalari";
import ZamanKurallari from "./ZamanKurallari";
import MekanKurallari from "./MekanKurallari";

import IleriGeri from "../components/IleriGeri";
import NavItem from "../components/NavItem";

const Sihirbaz = ({ children }) => {
  //let iValid = "yValid";
  const navList = [
    {
      aCss: "active",
      aId: "nav-CalismaDonemleri",
      aHref: "#wizard-CalismaDonemleri",
      iBaslik: "CalismaDonemleri",
      baslik: "Çalışma Dönemleri",
      icon: <BsArchiveFill size={"2em"} />,
    },
    {
      aCss: "",
      aId: "nav-RaporBilgileri",
      aHref: "#wizard-RaporBilgileri",
      iBaslik: "RaporBilgileri",
      baslik: "Rapor Bilgileri",
      icon: <BsFillFileEarmarkEaselFill size={"2em"} />,
    },
    {
      aCss: "",
      aId: "nav-DersGunleri",
      aHref: "#wizard-DersGunleri",
      iBaslik: "DersGunleri",
      baslik: "Ders Günleri",
      icon: <BsFillCalendar2DayFill size={"2em"} />,
    },
    {
      aCss: "",
      aId: "nav-DersSaatleri",
      aHref: "#wizard-DersSaatleri",
      iBaslik: "DersSaatleri",
      baslik: "Ders Saatleri",
      icon: <BsFillCalendar2DayFill size={"2em"} />,
    },
    {
      // aCss: `${iValid}`,
      aCss: "",
      aId: "nav-Branslar",
      aHref: "#wizard-Branslar",
      iBaslik: "Branslar",
      baslik: "Branşlar",
      icon: <BsCardChecklist size={"2em"} />,
    },
    {
      aCss: "",
      aId: "nav-Ogretmenler",
      aHref: "#wizard-Ogretmenler",
      iBaslik: "Ogretmenler",
      baslik: "Öğretmenler",
      icon: <BsPersonCircle size={"2em"} />,
    },
    {
      aCss: "",
      aId: "nav-SiniflarVeSubeler",
      aHref: "#wizard-SiniflarVeSubeler",
      iBaslik: "SiniflarVeSubeler",
      baslik: "Sınıflar ve Şubeler",
      icon: <BsHouseFill size={"2em"} />,
    },
    {
      aCss: "",
      aId: "nav-BinalarveDerslikler",
      aHref: "#wizard-BinalarveDerslikler",
      iBaslik: "BinalarveDerslikler",
      baslik: "Binalar ve Derslikler",
      icon: <BsBuilding size={"2em"} />,
    },
    {
      aCss: "",
      aId: "nav-DersHavuzu",
      aHref: "#wizard-DersHavuzu",
      iBaslik: "DersHavuzu",
      baslik: "Ders Havuzu",
      icon: <BsTsunami size={"2em"} />,
    },
    {
      aCss: "",
      aId: "nav-DersAtamalari",
      aHref: "#wizard-DersAtamalari",
      iBaslik: "DersAtamalari",
      baslik: "Ders Atamaları",
      icon: <BsBarChartSteps size={"2em"} />,
    },
    {
      aCss: "",
      aId: "nav-ZamanKurallari",
      aHref: "#wizard-ZamanKurallari",
      iBaslik: "ZamanKurallari",
      baslik: "Zaman Kuralları",
      icon: <BsFillAlarmFill size={"2em"} />,
    },
    {
      aCss: "",
      aId: "nav-MekanKurallari",
      aHref: "#wizard-MekanKurallari",
      iBaslik: "MekanKurallari",
      baslik: "Mekan Kuralları",
      icon: <BsFillMenuButtonWideFill size={"2em"} />,
    },
  ];

  //const [ileri, setileri] = useState("nav-RaporBilgileri");
  const navRef = useRef();
  const btnIleri = () => { 

    let active = navRef.current.querySelector(".active");
    let sayi = parseInt(active.dataset.it);
    let ileri = navRef.current.querySelector(`[data-it="${sayi + 1}"]`);
    ileri?.click();
  };
  const btnGeri = () => {
    let active = navRef.current.querySelector(".active");
    let sayi = parseInt(active.dataset.it);
    let geri = navRef.current.querySelector(`[data-it="${sayi - 1}"]`);
    geri?.click();
  };

  function fnVal(page,len) {
    //console.log(page,len)
    let ff = navRef.current.querySelector("#nav-"+page);
    if(len>0){
      ff.classList.add("yValid"); 
    }else{
      ff.classList.remove("yValid"); 
    }
  }
  // useEffect(() => {
  //   console.log(navRef.current);data-it
  // }, [navRef]);
  return (
    <div className="pt-2 cont-wizard">
      {/* <h2 className="card-header">Sihirbaz</h2> */}
      <div className="w-100">
        <hr></hr>
        {/* <a className="d-flex flex-center mb-4" href="/">
          <img
            className="me-2"
            src="../../assets/img/icons/spot-illustrations/falcon.png"
            alt=""
            width="45"
          />
          <span className="font-sans-serif fw-bolder fs-4 d-inline-block">
            DAĞITIMMATİK
          </span>
        </a> */}
        <div className="card theme-wizard mb-5" id="wizard">
          <div className="min-nav">
            <div className="card-header bg-light pt-3 pb-2">
              <ul
                ref={navRef}
                className="nav justify-content-between nav-wizard"
              >
                {navList.map((el, index) => {
                  return (
                    <NavItem
                      key={index}
                      aCss={el.aCss}
                      aId={el.aId}
                      aHref={el.aHref}
                      baslik={el.baslik}
                      icon={el.icon}
                      dataIt={index}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
          <IleriGeri btnGeri={btnGeri} btnIleri={btnIleri} />
          <div className="card-body" id="wizard-controller">
            <div className="tab-content">
              <div
                // px-sm-3 px-md-5
                className="tab-pane active"
                role="tabpanel"
                aria-labelledby="wizard-CalismaDonemleri"
                id="wizard-CalismaDonemleri"
              >
                <CalismaDonemleri fnVal={fnVal} />
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-RaporBilgileri"
                id="wizard-RaporBilgileri"
              >
                <RaporBilgileri  fnVal={fnVal} />
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-DersGunleri"
                id="wizard-DersGunleri"
              >
                <DersGunleri  fnVal={fnVal}/>
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-DersSaatleri"
                id="wizard-DersSaatleri"
              >
                <DersSaatleri  fnVal={fnVal}/>
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-Branslar"
                id="wizard-Branslar"
              >
                <Branslar size={"2em"}  fnVal={fnVal}/>
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-Ogretmenler"
                id="wizard-Ogretmenler"
              >
                <Ogretmenler size={"2em"} fnVal={fnVal}/>
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-SiniflarVeSubeler"
                id="wizard-SiniflarVeSubeler"
              >
                <SiniflarVeSubeler fnVal={fnVal}/>
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-BinalarveDerslikler"
                id="wizard-BinalarveDerslikler"
              >
                <BinalarveDerslikler fnVal={fnVal}/>
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-DersHavuzu"
                id="wizard-DersHavuzu"
              >
                <DersHavuzu   fnVal={fnVal}/>
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-DersAtamalari"
                id="wizard-DersAtamalari"
              >
                <DersAtamalari  fnVal={fnVal}/>
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-ZamanKurallari"
                id="wizard-ZamanKurallari"
              >
                <ZamanKurallari  fnVal={fnVal}/>
              </div>
              <div
                className="tab-pane "
                role="tabpanel"
                aria-labelledby="wizard-MekanKurallari"
                id="wizard-MekanKurallari"
              >
                <MekanKurallari  fnVal={fnVal}/>
              </div>
            </div>
          </div>
          <IleriGeri btnGeri={btnGeri} btnIleri={btnIleri} />
        </div>
      </div>

      <TContainer />
    </div>
  );
};
export default Sihirbaz;
