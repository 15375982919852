
import R1 from "../components/rules/R1";
import R2 from "../components/rules/R2";
import R3 from "../components/rules/R3";
import R4 from "../components/rules/R4";
import R5 from "../components/rules/R5";
import R6 from "../components/rules/R6";
import R7 from "../components/rules/R7";
import R8 from "../components/rules/R8";
import R9 from "../components/rules/R9";
import R10 from "../components/rules/R10";
import R11 from "../components/rules/R11";
import R12 from "../components/rules/R12";
import R13 from "../components/rules/R13";
import R14 from "../components/rules/R14";
import R15 from "../components/rules/R15";
import R16 from "../components/rules/R16";
import R17 from "../components/rules/R17";
import R18 from "../components/rules/R18";
import R19 from "../components/rules/R19";
import R20 from "../components/rules/R20";
import R21 from "../components/rules/R21";
import R101 from "../components/rules/R101";

const ModalComp = (props) => { 
  
  const componentList = {
    R1: <R1 {...props}/>,
    R2: <R2 {...props}/>,
    R3: <R3 {...props}/>,
    R4: <R4 {...props}/>,
    R5: <R5 {...props}/>,
    R6: <R6 {...props}/>,
    R7: <R7 {...props}/>,
    R8: <R8 {...props}/>,
    R9: <R9 {...props}/>,
    R10: <R10 {...props}/>,
    R11: <R11 {...props}/>,
    R12: <R12 {...props}/>,
    R13: <R13 {...props}/>,
    R14: <R14 {...props}/>,
    R15: <R15 {...props}/>,
    R16: <R16 {...props}/>,
    R17: <R17 {...props}/>,
    R18: <R18 {...props}/>,
    R19: <R19 {...props}/>,
    R20: <R20 {...props}/>,
    R21: <R21 {...props}/>,
    R101: <R101 {...props}/>
  };
  const getComponent = (name) => { 
    return componentList[name] ?? [];
  };
  return getComponent(props.name);
};

export default ModalComp;
