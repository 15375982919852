import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { setMe } from "../../helpers/generic";

const R18 = (props) => {
  const ZamanKurallariR = secureLocalStorage.getItem("ZamanKurallariR18"); 
  const initiall = {bosGunlereIzinVer:true, minDersSaati: 2, kuralaUyma: 100, kuralAktif: true };
  const [show, setShow] = useState(true);
  const [form, setForm] = useState(ZamanKurallariR ?? initiall);

  const handleClose = () => {
    props.sendModalKapat();
    setShow(false);
  };

//   const eventHandle = (propname, val, index = 0, element) => {
//     let dat = structuredClone(form);
//     dat[propname] = val ?? ""; 
//     setForm(dat);
//     secureLocalStorage.setItem("ZamanKurallariR18", dat);
//   };
  useEffect(() => {
  });

 
  const kaydet = () => { 
    secureLocalStorage.setItem("ZamanKurallariR18", form);
    handleClose();
  };
  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };
 
  const minDersSaatiData = [
    { target: { name: "minDersSaati" }, label: "2", value: "2" },
    { target: { name: "minDersSaati" }, label: "3", value: "3" },
    { target: { name: "minDersSaati" }, label: "4", value: "4" },
    { target: { name: "minDersSaati" }, label: "5", value: "5" },
    { target: { name: "minDersSaati" }, label: "6", value: "6" },
    { target: { name: "minDersSaati" }, label: "7", value: "7" },
    { target: { name: "minDersSaati" }, label: "8", value: "8" },
    { target: { name: "minDersSaati" }, label: "9", value: "9" },
  ];
  const kuralaUymaData = [
    {
      target: { name: "kuralaUyma" },
      value: "0",
      label: "%0 => isteği göz ardı et",
    },
    { target: { name: "kuralaUyma" }, value: "10", label: "%10" },
    { target: { name: "kuralaUyma" }, value: "20", label: "%20" },
    { target: { name: "kuralaUyma" }, value: "30", label: "%30" },
    { target: { name: "kuralaUyma" }, value: "40", label: "%40" },
    {
      target: { name: "kuralaUyma" },
      value: "50",
      label: "%50 => eşit ihtimal",
    },
    { target: { name: "kuralaUyma" }, value: "60", label: "%60" },
    { target: { name: "kuralaUyma" }, value: "70", label: "%70" },
    { target: { name: "kuralaUyma" }, value: "80", label: "%80" },
    { target: { name: "kuralaUyma" }, value: "90", label: "%90" },
    {
      target: { name: "kuralaUyma" },
      value: "100",
      label: "%100 => isteğe kesinlikle uy",
    },
  ];
  return (
    <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalYazi} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col> 
            <Form.Group>
              <Form.Label>Günlük minimum ders saati </Form.Label>
              <Select
                options={minDersSaatiData}
                defaultValue={
                  minDersSaatiData.filter((C) => C.value == form?.minDersSaati)[0]
                }
                name={"minDersSaati"}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Label>Kurala uyma oranı</Form.Label>
              <Select
                options={kuralaUymaData}
                defaultValue={
                  kuralaUymaData.filter((C) => C.value == form?.kuralaUyma)[0]
                }
                name={"kuralaUyma"}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Check
                type={"switch"}
                id={`bosGunlereIzinVer`}
                label={`Boş Günlere İzin Ver`}
                name={"bosGunlereIzinVer"}
                checked={form.bosGunlereIzinVer}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type={"switch"}
                id={`kuralAktif`}
                label={`Kural Aktif`}
                name={"kuralAktif"}
                checked={form.kuralAktif}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={kaydet}>
          Kaydet
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default R18;
