import React from "react";

const IleriGeri = (props) => {
  return (
    <div className="card-footer bg-light">
      <div className="">
        <ul className="pager wizard list-inline mb-0">
          <li className="previous">
            <button
              className="btn btn-link ps-0"
              type="button"
              onClick={props.btnGeri}
            // onClick={() => btnGeri()}
            >
              <span
                className="fas fa-chevron-left me-2"
                data-fa-transform="shrink-3"
              ></span>
              Geri
            </button>
          </li>
          <li className="next">
            <button
              className="btn btn-primary px-5 px-sm-6"
              type="button"
              // onClick={() => btnIleri()}
              onClick={props.btnIleri}
            >
              İleri
              <span
                className="fas fa-chevron-right ms-2"
                data-fa-transform="shrink-3"
              >
                {" "}
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default IleriGeri;