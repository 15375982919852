import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import {
  BsFillXSquareFill,
  BsFillCheckSquareFill,
  BsFillPencilFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import * as yup from "yup";

const MekanKurallari = (props) => {
  const bransListe = [
    {
      bransKod: "",
      brans: "Seçin",
    },
    {
      bransKod: "ADA",
      brans: "ADALET",
    },
    {
      bransKod: "BİLTEK",
      brans: "BİLİŞİM TEKNOLOJİLERİ",
    },
    {
      bransKod: "ALM",
      brans: "ALMANCA",
    },
  ];
  const liste = [
    {
      ad: "",
      soyad: "",
      listeAdi: "",
      brans: "",
      tc: "",
    },
  ];
  const localListe = secureLocalStorage.getItem("MekanKurallari");

  const [show, setShow] = useState(false);
  const [btnDuzenle, setBtnDuzenle] = useState(-1);
  const [modalListe, setModalListe] = useState(liste);
  const [data, setData] = useState(localListe ?? []);
  const [selectedOption, setSelectedOption] = useState(liste[0].bransKod);

  const handleShow = () => {
    setModalListe(liste);
    setBtnDuzenle(-1);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setBtnDuzenle(-1);
  };
  const silHandle = (key) => {
    const sil = structuredClone(data);
    sil.splice(key, 1);
    setData(sil);
    secureLocalStorage.setItem("MekanKurallari", sil);
    mesaj("success", "Silme işlemi başarılı");
  };
  const eventHandle = (propname, val, index = 0, element) => {
    let dat = structuredClone(modalListe);
    dat[index][propname] = val ? val : "";
    setModalListe(dat);
  };
  const kaydet = () => {
    let dat = structuredClone(data);
    dat.push(modalListe[0]);
    setData(dat);
    secureLocalStorage.setItem("MekanKurallari", dat);
    setShow(false);
    mesaj("success", "Yeni kayıt eklendi!");
  };
  const duzenleHandle = (key) => {
    setBtnDuzenle(key);
    const duzenle = structuredClone(data);
    setModalListe([duzenle[key]]);
    setShow(true);
  };
  const guncelleHandle = (key) => {
    setBtnDuzenle(key);
    const guncelle = structuredClone(data);
    guncelle[key] = modalListe[0];
    setData(guncelle);
    secureLocalStorage.setItem("MekanKurallari", guncelle);
    setShow(false);
    mesaj("success", "Güncelleme işlemi başarılı!");
  };

  useEffect(() => {
    props.fnVal("MekanKurallari", data?.length);
  }, [data]);

  return (
    <>

      <div className="card-body bg-light">
        <hr></hr>
        <h1 className="text-center">Mekan Kuralları</h1>
        <hr></hr>
        <div className="row">
          <div className="col">
            <h3 className="text-center">ÖĞRETMENLER</h3>
            <ul className="list-group">
              <a href="#" className="list-group-item">Bir öğretmenin ana dersliği </a>
              <a href="#" className="list-group-item">Bir öğretmenin ana derslikleri </a>
              <a href="#" className="list-group-item">Bir öğretmenin günlük maksimum bina değişimi </a>
              <a href="#" className="list-group-item">Bir öğretmenin haftalık maksimum bina değişimi </a>
              <a href="#" className="list-group-item">Bir öğretmenin bina değişimleri arasındaki minimum boşluk </a>
              <a href="#" className="list-group-item">Tüm öğretmenler için günlük maksimum bina değişimi </a>
              <a href="#" className="list-group-item">Tüm öğretmenler için haftalık maksimum bina değişimi </a>
              <a href="#" className="list-group-item">Tüm öğretmenler için bina değişimleri arasındaki minimum boşluk </a>
            </ul>
            <hr></hr>
            <h3 className="text-center">Derslikler</h3>
            <ul className="list-group">
              <a href="#" className="list-group-item">Bir dersliğin uygun olmadığı zamanlar</a>
            </ul>
          </div>
          <div className="col">
            <h3 className="text-center">Sınıflar</h3>
            <ul className="list-group">
              <a href="#" className="list-group-item">Bir sınıfın ana dersliği</a>
              <a href="#" className="list-group-item">Bir sınıfın ana derslikleri</a>
              <a href="#" className="list-group-item">Bir sınıfın günlük maksimum bina değişimi</a>
              <a href="#" className="list-group-item">Bir sınıfın haftalık maksimum bina değişimi</a>
              <a href="#" className="list-group-item">Bir sınıfın bina değişimleri arasındaki minimum boşluk</a>
              <a href="#" className="list-group-item">Tüm sınıflar için günlük maksimum bina değişimi</a>
              <a href="#" className="list-group-item">Tüm sınıflar için haftalık maksimum bina değişimi</a>
              <a href="#" className="list-group-item">Tüm sınıflar için bina değişimleri arasındaki minimum boşluk</a>
            </ul>
            <hr></hr>
            <h3 className="text-center">Sınıflara atanan dersler</h3>
            <ul className="list-group">
              <a href="#" className="list-group-item">Atanan bir dersin ana dersliği</a>
              <a href="#" className="list-group-item">Atanan bir dersin ana derslikleri</a> 
              <a href="#" className="list-group-item">Seçilen dersler arka arkaya ise aynı derslikte olsun</a> 
              <a href="#" className="list-group-item">Seçilen derslerin maksimum farklı derslik sayısı</a> 
            </ul>
          </div>
          <div className="col">
            <h3 className="text-center">Dersler</h3>
            <ul className="list-group">
              <a href="#" className="list-group-item">Bir dersin ana dersliği </a>
              <a href="#" className="list-group-item">Bir dersin ana derslikleri </a>
              <a href="#" className="list-group-item">Bir dersin etikete göre ana dersliği </a>
              <a href="#" className="list-group-item">Bir dersin etikete göre ana derslikleri </a>
              <a href="#" className="list-group-item">Ders + etiketin ana dersliği </a>
              <a href="#" className="list-group-item">Ders + etiketin ana derslikleri </a>
            </ul>
          </div>
        </div>
        <hr></hr>
        <button className="btn btn-primary">Tüm Mekan Kurallarını Sil</button>

      </div>

      <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mekan Kuralı Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="was-validated">
            <Form.Group className="mb-3">
              <Form.Label>Adı</Form.Label>
              <Form.Control
                type="text"
                required
                value={modalListe[0].ad}
                onChange={(e) => eventHandle("ad", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Soyadı</Form.Label>
              <Form.Control
                type="text"
                required
                value={modalListe[0].soyad}
                onChange={(e) => eventHandle("soyad", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Liste Adı</Form.Label>
              <Form.Control
                type="text"
                required
                value={modalListe[0].listeAdi}
                onChange={(e) => eventHandle("listeAdi", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tc Kimlik</Form.Label>
              <Form.Control
                type="number"
                required
                value={modalListe[0].tc}
                onChange={(e) => eventHandle("tc", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Branş</Form.Label>
              <br />
              <select
                className="form-control"
                value={selectedOption}
                required
                onChange={(e) => {
                  eventHandle("brans", e.target.value);
                  //setSelectedOption(e.target.value)
                }}
                defaultValue={modalListe[0].brans}
              >
                {
                  //bransListe ? (
                  bransListe.map((o, index) => {
                    return (
                      <option key={index} value={o.bransKod}>
                        {o.brans}
                      </option>
                    );
                  })
                }
              </select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {btnDuzenle === -1 ? (
            ""
          ) : (
            <Button
              variant="primary"
              onClick={(e) => guncelleHandle(btnDuzenle)}
            >
              Güncelle
            </Button>
          )}
          <Button variant="primary" onClick={kaydet}>
            Kaydet
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
          <br />
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default MekanKurallari;
