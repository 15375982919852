import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";

const R11 = (props) => {
  const Ogretmenler = secureLocalStorage.getItem("Ogretmenler") ?? [];
  const DersSaatleri = secureLocalStorage.getItem("DersSaatleri") ?? [];
  const ZamanKurallariR = secureLocalStorage.getItem("ZamanKurallariR11") ?? [];
  const [show, setShow] = useState(true);
  const [form, setForm] = useState({
    ogretmen: "",
    etiket: "",
    maxCalisma: "",
    kuralaUyma: "",
  });
  const opt = [{ label: "Seçin ", value: "" }];

  const handleClose = () => {
    props.sendModalKapat();
    setShow(false);
  };

  const eventHandle = (propname, val, index = 0, element) => {
    let dat = structuredClone(form);
    dat[propname] = val ?? ""; 
    setForm(dat);
    secureLocalStorage.setItem("ZamanKurallariR11", dat);
  };
  useEffect(() => {
    if (!DersSaatleri?.length > 0) {
      alert(
        'Bu kuralı oluşturabilmek için "Ders Günleri" eklemeniz gerekmektedir!'
      );
      handleClose();
    }
    if (!Ogretmenler?.length > 0) {
      alert(
        'Bu kuralı oluşturabilmek için "Öğretmen" eklemeniz gerekmektedir!'
      );
      handleClose();
    }
  });
  DersSaatleri?.forEach((element, index) => {
    opt.push({
      label: element.bas,
      value: index,
    });
  });
  DersSaatleri?.length > 0 ?? alert("Ders Saatleri kaydı yapınız.");
  DersSaatleri ?? opt.push({ label: "Ders Saatleri Ekleyin!", value: "Ekle" });
  const degisti = (e) => {
    // setselectSelected(e?.value);
    // if (e?.value == "") {
    //   alert("Öğretmen Seçiniz");
    //   return;
    // }
    // tabloyuTemizle();
  };
  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };

  return (
    <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalYazi} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <div>
              <ListGroup>
                <label>Öğretmen Seçin</label> 
                {Ogretmenler.map((elem, index) => {
                  return (
                    <ListGroup.Item
                      action
                      key={index}
                      onClick={() =>
                        eventHandle("ogretmen", elem.ad + " " + elem.soyad)
                      }
                    >
                      {elem.ad + " " + elem.soyad}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
          </Col>
          <Col>
            {/* <Select
              options={opt}
              onChange={(e) => {
                degisti(e);
              }}
              defaultValue={opt[0]}
            />
            <br /> */}
            <Form.Group>
              <Form.Label>Öğretmen</Form.Label>
              <Form.Control
                type="text"
                defaultValue={form?.ogretmen}
                //value={form?.ogretmen}
                //onChange={(e) => eventHandle("ogretmen", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Başlangıç Saati</Form.Label>
              <Select
                options={opt}
                onChange={(e) => {
                  degisti(e);
                }}
                defaultValue={opt[0]}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Bitiş Saati</Form.Label>
              <Select
                options={opt}
                onChange={(e) => {
                  degisti(e);
                }}
                defaultValue={opt[0]}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Maximum gün</Form.Label>
              <Select
                options={[
                  {label:"0",value:"0"},
                  {label:"1",value:"1"},
                  {label:"2",value:"2"},
                  {label:"3",value:"3"},
                  {label:"4",value:"4"},
                  {label:"5",value:"5"}
                ]}
                onChange={(e) => {
                  degisti(e);
                }}
                defaultValue={opt[0]}
              />
           
            </Form.Group>
             
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" onClick={kaydet}>
          Kaydet
        </Button> */}
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default R11;
