import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { setMe } from "../../helpers/generic";

const R15 = (props) => { 
  const initiall = {bosluk: 0 };
  const ZamanKurallariR = secureLocalStorage.getItem("ZamanKurallariR15") ?? {};
  const [show, setShow] = useState(true);
  const [form, setForm] = useState(ZamanKurallariR ?? initiall);
 
  const handleClose = () => {
    props.sendModalKapat();
    setShow(false);
  };
 
  useEffect(() => {
     
  });
    
  const kaydet = () => {
    secureLocalStorage.setItem("ZamanKurallariR15", form);
    handleClose();
  };
  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };
const bosluks=[
  {target: { name: "bosluk" },label:"0",value:"0"},
  {target: { name: "bosluk" },label:"1",value:"1"},
  {target: { name: "bosluk" },label:"2",value:"2"},
  {target: { name: "bosluk" },label:"3",value:"3"},
  {target: { name: "bosluk" },label:"4",value:"4"},
  {target: { name: "bosluk" },label:"5",value:"5"},
  {target: { name: "bosluk" },label:"6",value:"6"},
  {target: { name: "bosluk" },label:"7",value:"7"},
  {target: { name: "bosluk" },label:"8",value:"8"},
  {target: { name: "bosluk" },label:"9",value:"9"},
  {target: { name: "bosluk" },label:"10",value:"10"},
  {target: { name: "bosluk" },label:"11",value:"11"},
  {target: { name: "bosluk" },label:"12",value:"12"},
  {target: { name: "bosluk" },label:"13",value:"13"},
  {target: { name: "bosluk" },label:"14",value:"14"},
  {target: { name: "bosluk" },label:"15",value:"15"},
  {target: { name: "bosluk" },label:"16",value:"16"},
  {target: { name: "bosluk" },label:"17",value:"17"},
  {target: { name: "bosluk" },label:"18",value:"18"},
  {target: { name: "bosluk" },label:"19",value:"19"},
  {target: { name: "bosluk" },label:"20",value:"20"},
  {target: { name: "bosluk" },label:"21",value:"21"},
  {target: { name: "bosluk" },label:"22",value:"22"},
  {target: { name: "bosluk" },label:"23",value:"23"},
  {target: { name: "bosluk" },label:"24",value:"24"},
  {target: { name: "bosluk" },label:"25",value:"25"},
  {target: { name: "bosluk" },label:"26",value:"26"},
  {target: { name: "bosluk" },label:"27",value:"27"},
  {target: { name: "bosluk" },label:"28",value:"28"},
  {target: { name: "bosluk" },label:"29",value:"29"},
  {target: { name: "bosluk" },label:"30",value:"30"},
  {target: { name: "bosluk" },label:"31",value:"31"},
  {target: { name: "bosluk" },label:"32",value:"32"},
  {target: { name: "bosluk" },label:"33",value:"33"},
  {target: { name: "bosluk" },label:"34",value:"34"},
  {target: { name: "bosluk" },label:"35",value:"35"},
  {target: { name: "bosluk" },label:"36",value:"36"},
  {target: { name: "bosluk" },label:"37",value:"37"},
  {target: { name: "bosluk" },label:"38",value:"38"},
  {target: { name: "bosluk" },label:"39",value:"39"},
  {target: { name: "bosluk" },label:"40",value:"40"},
]
  return (
    <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalYazi} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
           
          <Col>
            <Form.Group>
              <Form.Label>Maximum boşluk</Form.Label>
              <Select
                options={bosluks} 
                defaultValue={
                  bosluks.filter((C) => C.value == form?.bosluk)[0]
                }
                onChange={(e) => setForm(setMe(form, e))}
              />
           
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={kaydet}>
          Kaydet
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default R15;
