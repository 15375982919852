import React, { useState, useEffect ,useRef} from "react";
import { Modal,  Button } from "react-bootstrap";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";

const R6 = (props) => { 
  const Ogretmenler = secureLocalStorage.getItem("Ogretmenler")??[];
  const DersGunleri = secureLocalStorage.getItem("DersGunleri")??[];
  const ZamanKurallariR = secureLocalStorage.getItem("ZamanKurallariR6")??[];
  const [show, setShow] = useState(true);
  const [selectSelected, setselectSelected] = useState([]); 
  const opt = [{ label: "Seçin yada arayın", value: "" }];

  const handleClose = () => {
    props.sendModalKapat(); 
    setShow(false);
  };
  Ogretmenler?.forEach((element) => {
    opt.push({  label: element.ad + " " + element.soyad,  value: element.ad + " " + element.soyad,  });
  });
  const degisti = (e) => {
    setselectSelected (e?.value)
  };
  const setBg=(value)=>{
    ZamanKurallariR.forEach((element,index) => {
      if(element.selected == selectSelected ){ZamanKurallariR.splice(index,1)}
    });
    ZamanKurallariR?.push({selected:selectSelected,data:parseInt(value)})
    secureLocalStorage.setItem("ZamanKurallariR6",ZamanKurallariR)
  }
  useEffect(()=>{
    if(! DersGunleri?.length > 0){ alert('Bu kuralı oluşturabilmek için "Ders Günleri" eklemeniz gerekmektedir!');handleClose() }
    if(! Ogretmenler?.length > 0){ alert('Bu kuralı oluşturabilmek için "Öğretmen" eklemeniz gerekmektedir!');handleClose() }
  })
  useEffect(()=>{
    var ff =ZamanKurallariR?.filter(D=>D.selected===selectSelected)
    //setselectSelected(ff?.data)
  },[selectSelected])
  const useForceUpdate=()=>{
    const [value, setValue] = useState(0); 
    return () => setValue(value => value + 1); 
  }
  const DersGunlerim=()=> {
    const forceUpdate = useForceUpdate();
    var ff =ZamanKurallariR?.filter(D=>D.selected===selectSelected)[0]?.data
    let ind =1;
    return (
      DersGunleri.map((el,index)=> {
        ind =index+1;
        return (
          <div key={ind} className="form-check dersBoyut">
            <input checked={ff == ind} onClick={forceUpdate} onChange={(e)=>setBg(e.target.id)} className="form-check-input" type="radio"  name="radios" id={ind} value={ind}  />
            <label className="form-check-label dersBoyut" htmlFor={ind}>
              {ind}
            </label>
          </div>
        );
      })
    );
  }
  return (
    <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalYazi} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select options={opt} onChange={(e)=>{degisti(e)}} defaultValue={opt[0]} />
        <br />
        {
          selectSelected?.length>0 ? (
            <DersGunlerim/>
          ) : null
        }
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" onClick={kaydet}>
          Kaydet
        </Button> */}
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default R6;
