import React from "react";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Animated } from "react-animated-css";
import * as yup from "yup";

export const mesaj = (tip, mesaj) => {
  toast[tip](mesaj, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
  
export const TContainer = ToastContainer;
export const Ganimated = Animated;

export const XvalidationSchema = yup.object().shape({
  baslik: yup
      .string()
      .min(5, "Başlık en az 5 karakter olmalıdır")
      .required("Zorunlu alan"),
  donem: yup.string().min(1, "Dönem seçilmek zorundadır.").required(),
  yil: yup.string().min(1, "Yıl seçilmek zorundadır.").required(),
});
export const setMe = (stateObj, e) => {
  let dat = structuredClone(stateObj);
  dat[e.target.name] = e.target?.checked ?? e.target?.value ?? e?.value ?? "";
  return dat;
};
export  const kuralaUymaData = [
  {
    target: { name: "kuralaUyma" },
    value: "0",
    label: "%0 => isteği göz ardı et",
  },
  { target: { name: "kuralaUyma" }, value: "10", label: "%10" },
  { target: { name: "kuralaUyma" }, value: "20", label: "%20" },
  { target: { name: "kuralaUyma" }, value: "30", label: "%30" },
  { target: { name: "kuralaUyma" }, value: "40", label: "%40" },
  {
    target: { name: "kuralaUyma" },
    value: "50",
    label: "%50 => eşit ihtimal",
  },
  { target: { name: "kuralaUyma" }, value: "60", label: "%60" },
  { target: { name: "kuralaUyma" }, value: "70", label: "%70" },
  { target: { name: "kuralaUyma" }, value: "80", label: "%80" },
  { target: { name: "kuralaUyma" }, value: "90", label: "%90" },
  {
    target: { name: "kuralaUyma" },
    value: "100",
    label: "%100 => isteğe kesinlikle uy",
  },
];
//export const validationSchema = (validationSchema) => {
//    return validationSchema = yup.object().shape({
//        baslik: yup
//            .string()
//            .min(5, "Ba�l�k en az 5 karakter olmal�d�r")
//            .required("Zorunlu alan"),
//        donem: yup.string().min(1, "D�nem se�ilmek zorundad�r.").required(),
//        yil: yup.string().min(1, "Y�l se�ilmek zorundad�r.").required(),
//    });
//}