import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { setMe,kuralaUymaData } from "../../helpers/generic";

const R19 = (props) => {
  const ZamanKurallariR = secureLocalStorage.getItem("ZamanKurallariR19"); 
  const initiall = {  maxDersSaati: 2, kuralaUyma: 100, kuralAktif: true };
  const [show, setShow] = useState(true);
  const [form, setForm] = useState(ZamanKurallariR ?? initiall);

  const handleClose = () => {
    props.sendModalKapat();
    setShow(false);
  };
 
  useEffect(() => {
  });

 
  const kaydet = () => { 
    secureLocalStorage.setItem("ZamanKurallariR19", form);
    handleClose();
  };
  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };
 
  const maxDersSaatiData = [
    { target: { name: "maxDersSaati" }, label: "1", value: "1" },
    { target: { name: "maxDersSaati" }, label: "2", value: "2" },
    { target: { name: "maxDersSaati" }, label: "3", value: "3" },
    { target: { name: "maxDersSaati" }, label: "4", value: "4" },
    { target: { name: "maxDersSaati" }, label: "5", value: "5" },
    { target: { name: "maxDersSaati" }, label: "6", value: "6" },
    { target: { name: "maxDersSaati" }, label: "7", value: "7" },
    { target: { name: "maxDersSaati" }, label: "8", value: "8" },
  ];
 
  return (
    <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalYazi} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col> 
            <Form.Group>
              <Form.Label>Arka arkaya maksimum ders saati </Form.Label>
              <Select
                options={maxDersSaatiData}
                defaultValue={
                  maxDersSaatiData.filter((C) => C.value == form?.maxDersSaati)[0]
                }
                name={"maxDersSaati"}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Label>Kurala uyma oranı</Form.Label>
              <Select
                options={kuralaUymaData}
                defaultValue={
                  kuralaUymaData.filter((C) => C.value == form?.kuralaUyma)[0]
                }
                name={"kuralaUyma"}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Check
                type={"switch"}
                id={`kuralAktif`}
                label={`Kural Aktif`}
                name={"kuralAktif"}
                checked={form.kuralAktif}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={kaydet}>
          Kaydet
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default R19;
