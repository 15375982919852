import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
//import { Provider } from "react-redux";
// import {store} from './store/store'
import { BrowserRouter, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
    {/* <Provider store={null}>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </Provider> */}
  </BrowserRouter>
);
