import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";

const R7 = (props) => {
  const Ogretmenler = secureLocalStorage.getItem("Ogretmenler") ?? [];
  const DersGunleri = secureLocalStorage.getItem("DersGunleri") ?? [];
  const ZamanKurallariR = secureLocalStorage.getItem("ZamanKurallariR7") ?? [];
  const [show, setShow] = useState(true);
  const [form, setForm] = useState({
    ogretmen: "",
    etiket: "",
    maxCalisma: "",
    kuralaUyma: "",
  });
  const opt = [{ label: "Seçin yada arayın", value: "" }];

  const handleClose = () => {
    props.sendModalKapat();
    setShow(false);
  };
  Ogretmenler?.forEach((element) => {
    opt.push({
      label: element.ad + " " + element.soyad,
      value: element.ad + " " + element.soyad,
    });
  });

  const eventHandle = (propname, val, index = 0, element) => {
    let dat = structuredClone(form);
    dat[propname] = val ?? ""; 
    setForm(dat);
    secureLocalStorage.setItem("ZamanKurallariR7", dat);
  };
  useEffect(() => {
    if (!DersGunleri?.length > 0) {
      alert(
        'Bu kuralı oluşturabilmek için "Ders Günleri" eklemeniz gerekmektedir!'
      );
      handleClose();
    }
    if (!Ogretmenler?.length > 0) {
      alert(
        'Bu kuralı oluşturabilmek için "Öğretmen" eklemeniz gerekmektedir!'
      );
      handleClose();
    }
  });
  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };

  return (
    <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalYazi} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <div>
              <ListGroup>
                <label>Öğretmen Seçin</label> 
                {Ogretmenler.map((elem, index) => {
                  return (
                    <ListGroup.Item
                      action
                      key={index}
                      onClick={() =>
                        eventHandle("ogretmen", elem.ad + " " + elem.soyad)
                      }
                    >
                      {elem.ad + " " + elem.soyad}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
          </Col>
          <Col>
            {/* <Select
              options={opt}
              onChange={(e) => {
                degisti(e);
              }}
              defaultValue={opt[0]}
            />
            <br /> */}
            <Form.Group>
              <Form.Label>Öğretmen</Form.Label>
              <Form.Control
                type="text"
                defaultValue={form?.ogretmen}
                //value={form?.ogretmen}
                //onChange={(e) => eventHandle("ogretmen", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Etiket</Form.Label>
              <Form.Control
                type="text"
                value={form?.etiket}
                onChange={(e) => eventHandle("etiket", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Maksimum çalışma saati</Form.Label>
              <Form.Control
                type="text"
                value={form?.maxCalisma}
                onChange={(e) => eventHandle("maxCalisma", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Kurala uyma oranı</Form.Label>
              <Form.Control
                type="text"
                value={form?.kuralaUyma}
                onChange={(e) => eventHandle("kuralaUyma", e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" onClick={kaydet}>
          Kaydet
        </Button> */}
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default R7;
