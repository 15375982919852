import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import {
    BsFillXSquareFill,
    BsFillCheckSquareFill,
    BsFillPencilFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";
import { mesaj, TContainer, Ganimated, XvalidationSchema } from "../helpers/generic";
import * as yup from "yup";

const BinalarveDerslikler = (props) => {
    const liste = [
        {
            bina: "Bina",
            derslik: "Derslik",
            kapasite: "0",
        },
    ];
    const localListe = secureLocalStorage.getItem("BinalarveDerslikler");

    const [show, setShow] = useState(false);
    const [btnDuzenle, setBtnDuzenle] = useState(-1);
    const [modalListe, setModalListe] = useState(liste);
    const [data, setData] = useState(localListe ?? []);
    const [selectedOption, setSelectedOption] = useState(liste[0].bransKod);

    const handleShow = () => {
        setModalListe(liste);
        setBtnDuzenle(-1);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setBtnDuzenle(-1);
    };
    const silHandle = (key) => {
        const sil = structuredClone(data);
        sil.splice(key, 1);
        setData(sil);
        secureLocalStorage.setItem("BinalarveDerslikler", sil);
        mesaj("success", "Silme işlemi başarılı");

    };
    const eventHandle = (propname, val, index = 0, element) => {
        let dat = structuredClone(modalListe);
        dat[index][propname] = val ? val : "";
        setModalListe(dat);
    };
    const kaydet = () => {
        let dat = structuredClone(data);
        dat.push(modalListe[0]);
        setData(dat);
        secureLocalStorage.setItem("BinalarveDerslikler", dat);
        setShow(false);
        mesaj("success", "Yeni kayıt eklendi!");
    };
    const duzenleHandle = (key) => {
        setBtnDuzenle(key);
        const duzenle = structuredClone(data);
        setModalListe([duzenle[key]]);
        setShow(true);
    };
    const guncelleHandle = (key) => {
        setBtnDuzenle(key);
        const guncelle = structuredClone(data);
        guncelle[key] = modalListe[0];
        setData(guncelle);
        secureLocalStorage.setItem("BinalarveDerslikler", guncelle);
        setShow(false);
        mesaj("success", "Güncelleme işlemi başarılı!");
    };

    useEffect(() => {
        props.fnVal("BinalarveDerslikler", data?.length);
        //setData(localListe ?? []);
    }, [data]);

    return (
        <>
            {/* <TContainer /> */}
            <div className="card-body bg-light">
                <hr></hr>
                <Ganimated isVisible={true} className={data?.length == 0 ? "infinite " : ""}>
                    <Button variant="primary" onClick={handleShow}>
                        Bina ve Derslik Ekle
                    </Button>
                </Ganimated>

                <hr></hr>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Sıra</th>
                            <th>Bina</th>
                            <th>Derslik</th>
                            <th>Kapasite</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data ? (
                            data.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <span
                                                className="handCursor"
                                                data-tip="Sil"
                                                onClick={() => {
                                                    window.confirm("Silmek istediğinizden emin misiniz?")
                                                        ? silHandle(index)
                                                        : mesaj("success", "İptal ettiniz");
                                                }}
                                            >
                                                <BsFillXSquareFill color="red" />
                                            </span>
                                            <span>&nbsp;</span>
                                            <span
                                                className="handCursor"
                                                data-tip="Düzenle"
                                                onClick={() => {
                                                    //setShow(true);
                                                    //setModalListe([el])
                                                    duzenleHandle(index);
                                                }}
                                            >
                                                <BsFillPencilFill color="green" />
                                            </span>
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>{el.bina}</td>
                                        <td>{el.derslik}</td>
                                        <td>{el.kapasite}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>yükleniyor</tr>
                        )}
                    </tbody>
                </Table>
                {data ? data?.length + " adet eklediniz." : ""}
                <hr></hr>
            </div>

            <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>BinalarveDerslikler Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="was-validated">
                        <Form.Group className="mb-3">
                            <Form.Label>Bina Adı</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={modalListe[0].bina}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                onChange={(e) => eventHandle("bina", e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Derslik Adı</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={modalListe[0].derslik}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                onChange={(e) => eventHandle("derslik", e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Kapasite</Form.Label>
                            <Form.Control
                                type="number"
                                required
                                value={modalListe[0].kapasite}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                onChange={(e) => eventHandle("kapasite", e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {btnDuzenle === -1 ? (
                        ""
                    ) : (
                        <Button
                            variant="primary"
                            onClick={(e) => guncelleHandle(btnDuzenle)}
                        >
                            Güncelle
                        </Button>
                    )}
                    <Button variant="primary" onClick={kaydet}>
                        Kaydet
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Kapat
                    </Button>
                    <br />
                </Modal.Footer>
            </Modal>
            {props.children}
        </>
    );
};
export default BinalarveDerslikler;
