import React from "react";

export default function Kayit() {
  return (
    <main className="main" id="top">
      <div className="container" data-layout="container">
        {/* <script>
          var isFluid = JSON.parse(localStorage.getItem('isFluid'));
          if (isFluid) {
            var container = document.querySelector('[data-layout]');
            container.classList.remove('container');
            container.classList.add('container-fluid');
          }
        </script> */}
        <div className="row flex-center min-vh-100 py-6">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <a className="d-flex flex-center mb-4" href="../../../index.html">
              <img
                className="me-2"
                src="../src/assets/img/icons/spot-illustrations/falcon.png"
                alt=""
                width="58"
              />
              <span className="font-sans-serif fw-bolder fs-5 d-inline-block">
                Dağıtmatik
              </span>
            </a>
            <div className="card">
              <div className="card-body p-4 p-sm-5">
                <div className="row flex-between-center mb-2">
                  <div className="col-auto">
                    <h5>Kayıt Ol</h5>
                  </div>
                  <div className="col-auto fs--1 text-600">
                    <span className="mb-0 undefined">Hesabım var</span>{" "}
                    <span>
                      <a href="./Giris">Giriş</a>
                    </span>
                  </div>
                </div>
                <form className="was-validated">
                  <div className="mb-3">
                    <input
                      className="form-control"
                      type="text"
                      autoComplete="on"
                      required
                      placeholder="Ad"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      className="form-control"
                      type="email"
                      required
                      autoComplete="on"
                      placeholder="E-posta"
                    />
                  </div>
                  <div className="row gx-2">
                    <div className="mb-3 col-sm-6">
                      <input
                        className="form-control"
                        type="password"
                        required
                        autoComplete="on"
                        placeholder="Şifre"
                      />
                    </div>
                    <div className="mb-3 col-sm-6">
                      <input
                        className="form-control"
                        type="password"
                        required
                        autoComplete="on"
                        placeholder="Şifre Tekrar"
                      />
                    </div>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="basic-register-checkbox"
                    />
                    <label
                      className="form-label"
                      htmlFor="basic-register-checkbox"
                    >
                      <a href="#!">Şartları </a>ve{" "}
                      <a href="#!">gizlilik politikasını</a> kabul ediyorum
                    </label>
                  </div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary d-block w-100 mt-3"
                      type="submit"
                      name="submit"
                    >
                      Kayıt Ol
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
