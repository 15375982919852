import React, { useState, useEffect } from "react";
import { Form, Button, Table } from "react-bootstrap";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import {
  BsFillXSquareFill,
  BsFillCheckSquareFill,
  BsFillPencilFill,
  BsFillPersonLinesFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";

export default function DersSaatleri(props) {
  const [basSaat, setBasSaat] = useState("08:00");
  const [bitSaat, setBitSaat] = useState("08:40");
  const [grup, setGrup] = useState("Sabah");

  const liste = secureLocalStorage.getItem("DersSaatleri");
  const [data, setData] = useState(liste ? liste : []);

 
  const eventHandle = (i, e, propname) => {
    let list = data;

    if (propname === "chk") {
      list[i][propname] = e.target.checked ? 1 : 0;
    } else {
      if (propname === "grup") {
        list[i][propname] = e;
      } else {
        list[i][propname] = e.target.value;
        //console.log("değil", propname, list);
      }
    }
    setData(list);
  };
  const kaydet = () => {
    let li1 = structuredClone(data ? data : []);
    secureLocalStorage.setItem("DersSaatleri", li1);
    mesaj("success", "Yeni kayıt eklendi!");
  };
  const silHandle = (key) => {
    const sil = structuredClone(data);
    sil.splice(key, 1);
    setData(sil);
    secureLocalStorage.setItem("DersSaatleri", sil);
    mesaj("success", "Silme işlemi başarılı");
  };
  const yeniAraklikEkle = () => {
    var basdt = new Date("1970-01-01T" + basSaat);
    var bitdt = new Date("1970-01-01T" + bitSaat);
    //console.log(basdt, bitdt);
    if (bitdt < basdt) {
      alert("Bitiş saati başlangıçtan küçük olamaz!");
    } else {
      var kk = { chk: 1, bas: basSaat, bit: bitSaat, grup: grup };
      //console.log(data);
      let li2 = structuredClone(data ?? []);

      li2.push(kk);
      setData(li2);
      secureLocalStorage.setItem("DersSaatleri", li2);
    }
  };
  useEffect(() => {
    setData(liste);
    props.fnVal("DersSaatleri",data?.length);
  }, [data]);
  return (
    <>
      {/* <TContainer /> */}
      <div className="card-body bg-light">
        <hr></hr>
        <Form className="was-validated">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>#</th>
                <th>Başlangıç</th>
                <th>Bitiş</th>
                <th>Grup</th>
              </tr>
            </thead>
            <tbody>
              {data ? (
                data.map((el, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          id={"chk-" + index}
                          defaultChecked={el.chk === 1 ? "checked" : ""}
                          onClick={(e) => eventHandle(index, e, "chk")}
                        />
                        <span
                          className="handCursor"
                          data-tip="Sil"
                          onClick={() => {
                            window.confirm(
                              "Silmek istediğinizden emin misiniz?"
                            )
                              ? silHandle(index)
                              : mesaj("success", "İptal ettiniz");
                          }}
                        >
                          <BsFillXSquareFill color="red" />
                        </span>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          defaultValue={el.bas}
                          onChange={(e) => eventHandle(index, e, "bas")}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          defaultValue={el.bit}
                          onChange={(e) => eventHandle(index, e, "bit")}
                        ></Form.Control>
                      </td>
                      <td>
                        <select
                          className="dropdown"
                          value={el.grup}
                          onChange={(e) => {
                            eventHandle(index, e, grup);
                          }}
                        >
                          <option value="Sabah">Sabah</option>
                          <option value="Öğlen">Öğlen</option>
                        </select>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th>Kayıt Ekleyiniz</th>
                  <th>Kayıt Ekleyiniz</th>
                  <th>Kayıt Ekleyiniz</th>
                  <th>Kayıt Ekleyiniz</th>
                  <th>Kayıt Ekleyiniz</th>
                </tr>
              )}
            </tbody>
          </Table>
        </Form>
        <Table striped bordered hover className="tblBgDarkGrey">
          <thead>
            <tr>
              <th></th>
              <th>Başlangıç Saat</th>
              <th>Bitiş Saat</th>
              <th>Grup</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    yeniAraklikEkle(e);
                  }}
                >
                  Yeni aralık ekle
                </Button>
              </td>
              <td>
                <Form.Control
                  type="time"
                  value={basSaat}
                  onChange={(e) => setBasSaat(e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="time"
                  value={bitSaat}
                  onChange={(e) => setBitSaat(e.target.value)}
                ></Form.Control>
              </td>
              <td>
                <select
                  className="dropdown"
                  value={grup}
                  onChange={(e) => setGrup(e.target.value)}
                >
                  <option value="Sabah">Sabah</option>
                  <option value="Öğlen">Öğlen</option>
                </select>
              </td>
            </tr>
          </tbody>
        </Table>

        <Button
          variant="primary"
          onClick={() => {
            kaydet();
          }}
        >
          Kaydet
        </Button>
        <hr></hr>
      </div>
    </>
  );
}
