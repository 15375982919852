import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";

const R13 = (props) => {
  const Ogretmenler = secureLocalStorage.getItem("Ogretmenler") ?? [];
  const DersSaatleri = secureLocalStorage.getItem("DersSaatleri") ?? [];
  const ZamanKurallariR = secureLocalStorage.getItem("ZamanKurallariR13") ?? [];
  const [show, setShow] = useState(true);
  const [form, setForm] = useState({
    ogretmen: "",
    etiket: "",
    maxCalisma: "",
    kuralaUyma: "",
  });
  const opt = [{ label: "Seçin ", value: "" }];

  const handleClose = () => {
    props.sendModalKapat();
    setShow(false);
  };

  const eventHandle = (propname, val, index = 0, element) => {
    let dat = structuredClone(form);
    dat[propname] = val ?? ""; 
    setForm(dat);
    secureLocalStorage.setItem("ZamanKurallariR13", dat);
  };
  useEffect(() => {
    if (!DersSaatleri?.length > 0) {
      alert(
        'Bu kuralı oluşturabilmek için "Ders Günleri" eklemeniz gerekmektedir!'
      );
      handleClose();
    }
    if (!Ogretmenler?.length > 0) {
      alert(
        'Bu kuralı oluşturabilmek için "Öğretmen" eklemeniz gerekmektedir!'
      );
      handleClose();
    }
  });
  DersSaatleri?.forEach((element, index) => {
    opt.push({
      label: element.bas,
      value: index,
    });
  });
  DersSaatleri?.length > 0 ?? alert("Ders Saatleri kaydı yapınız.");
  DersSaatleri ?? opt.push({ label: "Ders Saatleri Ekleyin!", value: "Ekle" });
  const degisti = (e) => {
    // setselectSelected(e?.value);
    // if (e?.value == "") {
    //   alert("Öğretmen Seçiniz");
    //   return;
    // } 
  };
  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };

  return (
    <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalYazi} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
           
          <Col>
            <Form.Group>
              <Form.Label>Minimum gün</Form.Label>
              <Select
                options={[
                  {label:"1",value:"1"},
                  {label:"2",value:"2"},
                  {label:"3",value:"3"},
                  {label:"4",value:"4"},
                  {label:"5",value:"5"}
                ]}
                onChange={(e) => {
                  degisti(e);
                }}
                defaultValue={opt[0]}
              />
           
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" onClick={kaydet}>
          Kaydet
        </Button> */}
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default R13;
