import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import secureLocalStorage from "react-secure-storage";
import * as yup from "yup";

export default function RaporBilgileri(props) {
  const localListe = secureLocalStorage.getItem("RaporBilgileri");
  const initialInputDatas = [
    {
      raporBasligi: "T.C.\r\n[ILCE] Kaymakamlığı\r\n[OKUL]",
      ogretmenElProgramiSayisi: 50,
      ogretmenElProgramKonusu: "Ders Programınız",
      ogretmenElProgramBasligi: "Sayın [OGRETMEN]",
      ogretmenElProgramYazisi:
        "[DONEM]de xx/xx/xxxx tarihinden itibaren uygulanacak programda haftalık ders dağılımınız ve nöbet göreviniz aşağıya çıkarılmıştır.\r\n\r\nBilgilerinizi ve gereğini rica eder başarılar dilerim.",
      okulMuduru: "Ebru Hoca",
      okulMudurYardimcisi: "Müdür Yardımcısı",
    },
  ];
  const [inputDatas, setInputDatas] = useState(
    localListe ? localListe : initialInputDatas
  );

  const eventHandle = (propname, val, index = 0, element) => {
    let dat = structuredClone(inputDatas);
    dat[index][propname] = val ? val : "";
    setInputDatas(dat);
  };

  const kaydet = () => {
    let ff = window.sbmt(".plsValid");
    console.log(ff)
    let li1 = structuredClone(inputDatas ? inputDatas : []);
    secureLocalStorage.setItem("RaporBilgileri", li1);
    props.fnVal("RaporBilgileri", 1);
    mesaj("success", "Başarıyla kaydedildi!");
  };
  useEffect(() => {
    props.fnVal("RaporBilgileri", localListe == null ? 0 : 1);
  }, []);
  return (
    <>
      <div className="card-body bg-light">
        <hr></hr>
        <Form className="was-validated">
          <Form.Group className="mb-3">
            <Form.Label>Rapor Başlığı</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              required
              className="textCenter"
              value={inputDatas[0].raporBasligi}
              //value={valueAciklama}
              onChange={(e) => {
                eventHandle("raporBasligi", e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Öğretmen El Programı Sayısı</Form.Label>
            <Form.Control
              type="text"
              required
              value={inputDatas[0].ogretmenElProgramYazisi}
              onChange={(e) => {
                eventHandle("ogretmenElProgramYazisi", e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Öğretmen El Programı Konusu</Form.Label>
            <Form.Control
              type="text"
              required
              value={inputDatas[0].ogretmenElProgramKonusu}
              onChange={(e) => {
                eventHandle("ogretmenElProgramKonusu", e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Öğretmen El Programı Başlığı</Form.Label>
            <Form.Control
              type="text"
              required
              value={inputDatas[0].ogretmenElProgramBasligi}
              onChange={(e) => {
                eventHandle("ogretmenElProgramBasligi", e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Öğretmen El Programı Yazısı</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              required
              value={inputDatas[0].ogretmenElProgramYazisi}
              onChange={(e) => {
                eventHandle("ogretmenElProgramYazisi", e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Okul Müdürü</Form.Label>
            <Form.Control
              type="text"
              required
              value={inputDatas[0].okulMuduru}
              onChange={(e) => {
                eventHandle("okulMuduru", e.target.value);
              }}
            />
            <div className="invalid-feedback">Boş geçilemez!</div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Müdür Yardımcısı</Form.Label>
            <Form.Control
              type="text"
              required
              value={inputDatas[0].okulMudurYardimcisi}
              onChange={(e) => {
                eventHandle("okulMudurYardimcisi", e.target.value);
              }}
            />
            <div className="invalid-feedback">Boş geçilemez!</div>
          </Form.Group>
        </Form>
        {/* <Button
          variant="primary"
          onClick={(e) => {
            setInputDatas(initialInputDatas);
          }}
        >
          Varsayılan
        </Button> */}
        <span>&nbsp;</span>
        <Ganimated className={inputDatas?.length > 0 ? "ff " : "infinite"}>
          <Button
            variant="primary"
            onClick={(e) => {
              kaydet();
            }}
          >
            Kaydet
          </Button>
        </Ganimated>

        <hr></hr>
      </div>
      {/* <TContainer /> */}
      {/* <Modal size="xl" show={false} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Rapor Bilgileri</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Rapor Başlığı</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                className="textCenter"
                //style={textCenter}
                value={"T.C.\r\n[ILCE] Kaymakamlığı\r\n[OKUL]"}
              //value={valueAciklama}
              //onChange={handleAciklamaChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Öğretmen El Programı Sayısı</Form.Label>
              <Form.Control
                type="text"
                onChange={handleBaslikChange}
                value={"50"}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Öğretmen El Programı Konusu</Form.Label>
              <Form.Control
                type="text"
                onChange={handleBaslikChange}
                value={"Ders Programınız"}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Öğretmen El Programı Başlığı</Form.Label>
              <Form.Control
                type="text"
                onChange={handleBaslikChange}
                value={"Sayın [OGRETMEN]"}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Öğretmen El Programı Yazısı</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                //style={textCenter}
                value={
                  "[DONEM]de xx/xx/xxxx tarihinden itibaren uygulanacak programda haftalık ders dağılımınız ve nöbet göreviniz aşağıya çıkarılmıştır.\r\n\r\nBilgilerinizi ve gereğini rica eder başarılar dilerim."
                }
              //value={valueAciklama}
              //onChange={handleAciklamaChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Okul Müdürü</Form.Label>
              <Form.Control
                type="text"
                onChange={handleBaslikChange}
                value={"Ebru Hoca"}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Müdür Yardımcısı</Form.Label>
              <Form.Control
                type="text"
                onChange={handleBaslikChange}
                value={"Müdür Yardımcısı"}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleVarsayilan}>
            Varsayılan
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Kaydet
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}
