import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Button, InputGroup, Table } from "react-bootstrap";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import {
  BsFillXSquareFill,
  BsFillCheckSquareFill,
  BsFillPencilFill,
  BsFillPersonLinesFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { variables } from "../helpers/Variables";

const DersProgramListesi = ({ children }) => {
  const initialInputDatas = [
    {
      varsayilan: 1,
      baslik: "",
      yil: "",
      donem: "",
      aciklama: "Açıklama",
      kilit: 1,
      ogretmen: 1,
      calismaDonemiKitlensin: 1,
      ogretmenlerDersPrograminiGorebilsin: 0,
      varsayilanCalismaDonemiOlsun: 1,
    },
  ];
  const [show, setShow] = useState(false);
  const [btnDuzenle, setBtnDuzenle] = useState(-1);
  const handleClose = () => {
    setShow(false);
    setBtnDuzenle(-1);
  };
  const handleShow = () => {
    setInputDatas(initialInputDatas);
    setShow(true);
  };
  const [inputDatas, setInputDatas] = useState(initialInputDatas);
  const liste = secureLocalStorage.getItem("DersProgramListesi");
  const [data, setData] = useState(liste);

  const validationSchema = yup.object().shape({
    baslik: yup
      .string()
      .min(5, "Başlık en az 5 karakter olmalıdır")
      .required("Zorunlu alan"),
    donem: yup.string().min(1, "Dönem seçilmek zorundadır.").required(),
    yil: yup.string().min(1, "Yıl seçilmek zorundadır.").required(),
  });
  const kaydet = () => {
    validationSchema.isValid(inputDatas[0]).then((valid) => {
      //console.log(valid); // => true
      if (valid) {
        let li1 = structuredClone(data ? data : []);
        li1.push(inputDatas[0]);
        setData(li1);
        secureLocalStorage.setItem("DersProgramListesi", li1);
        mesaj("success", "Yeni kayıt eklendi!");
        setShow(false);
      }
    });
    validationSchema.validate(inputDatas[0]).catch(function (err) {
      mesaj("error", err.errors[0]);
      //console.log(inputDatas[0]);
    });
  };
  const eventHandle = (propname, val, index = 0, element) => {
    let dat = structuredClone(inputDatas);
    dat[index][propname] = val ? val : "";
    setInputDatas(dat);
  };
  const duzenleHandle = (key) => {
    setBtnDuzenle(key);
    const duzenle = structuredClone(data);
    setInputDatas([duzenle[key]]);
    setShow(true);
  };
  const guncelleHandle = (key) => {
    const guncelle = structuredClone(data);
    guncelle[key] = inputDatas[0];
    //console.log(guncelle);
    setData(guncelle);
    setShow(false);
    mesaj("success", "Güncelleme işlemi başarılı!");
  };
  const silHandle = (key) => {
    const sil = structuredClone(data);
    sil.splice(key, 1);
    setData(sil);
    secureLocalStorage.setItem("DersProgramListesi", sil);
    mesaj("success", "Silme işlemi başarılı");
  };

  const [showExcel, setShowExcel] = useState(false);
  const handleCloseExcel = () => {
    setShowExcel(false);
  };
  const handleShowExcel = () => {
    setShowExcel(true);
  };
  useEffect(() => {
    setData(liste);
    setInputDatas(initialInputDatas);
  }, []);

  return (
    <>
      <div className="card-body bg-light">
        <hr></hr>
        <h2 className="card-header">Ders Programları</h2>
        <hr></hr>
        <div className="flex">
          <Link to={`/Sihirbaz`}>
            {/* {console.log(8, 222)} */}
            <Ganimated
              isVisible={true}
              className={data?.length == 0 || data == null ? "infinite " : ""}
            >
              <Button variant="primary" type="button">
                Yeni Ekle
              </Button>
            </Ganimated>
          </Link>
          <div>&nbsp;</div>
          <Button variant="primary" onClick={handleShowExcel}>
            Excel İle Toplu Ekle
          </Button>
        </div>

        <br /> <br />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Varsayılan</th>
              <th>Başlık</th>
              <th>Yıl</th>
              <th>Dönem</th>
              <th>Kilit</th>
              <th>Öğretmen</th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              data.map((el, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span
                        className="handCursor"
                        data-tip="Sil"
                        onClick={() => {
                          window.confirm("Silmek istediğinizden emin misiniz?")
                            ? silHandle(index)
                            : mesaj("success", "İptal ettiniz");
                        }}
                      >
                        <BsFillXSquareFill color="red" />
                      </span>
                      <span>&nbsp;</span>
                      <span
                        className="handCursor"
                        data-tip="Düzenle"
                        onClick={() => {
                          //setBtnGuncelle(index);
                          duzenleHandle(index);
                        }}
                      >
                        <BsFillPencilFill color="green" />
                      </span>
                      <span>&nbsp;</span>
                      <span
                        className="handCursor"
                        data-tip="Kullanıcı Yetkileri"
                        onClick={() => {
                          alert("Düzenlemek istediğinizden emin misiniz?");
                        }}
                      >
                        <BsFillPersonLinesFill color="black" />
                      </span>
                    </td>
                    <td>
                      {el.varsayilan === 1 ? (
                        <BsFillCheckSquareFill color="green" />
                      ) : (
                        <BsFillCheckSquareFill color="red" />
                      )}
                    </td>
                    <td>{el.baslik}</td>
                    <td>{el.yil}</td>
                    <td>{el.donem}</td>
                    <td>
                      {el.kilit === 1 ? (
                        <BsFillCheckSquareFill color="green" />
                      ) : (
                        <BsFillCheckSquareFill color="red" />
                      )}
                    </td>
                    <td>
                      {el.ogretmen === 1 ? (
                        <BsFillCheckSquareFill color="green" />
                      ) : (
                        <BsFillCheckSquareFill color="red" />
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>Kayıt Ekleyiniz</td>
                <td>Kayıt Ekleyiniz</td>
                <td>Kayıt Ekleyiniz</td>
                <td>Kayıt Ekleyiniz</td>
                <td>Kayıt Ekleyiniz</td>
                <td>Kayıt Ekleyiniz</td>
                <td>Kayıt Ekleyiniz</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Yeni çalışma dönemi ekleme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="was-validated">
            <Form.Group className="mb-3">
              <Form.Label>Yılı</Form.Label>
              <br />
              <select
                className="form-control"
                onChange={(e) => {
                  eventHandle("yil", e.target.value);
                }}
                defaultValue={inputDatas[0].yil}
              >
                <option value="">Seçin</option>
                <option value="2024-2025">2024-2025</option>
                <option value="2023-2024">2023-2024</option>
                <option value="2022-2023">2022-2023</option>
                <option value="2021-2022">2021-2022</option>
              </select>
              <span className="validation-error">
                {/* {formik.errors.donem } */}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Dönemi</Form.Label>
              <br />

              <select
                name="donem"
                className="form-control"
                onChange={(e) => {
                  eventHandle("donem", e.target.value);
                }}
                defaultValue={inputDatas[0].donem}
              >
                <option value="">Seçin</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
              <span className="validation-error">
                {/* {formik.errors.donem ? "Zorunlu alan" : ""} */}
              </span>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Başlık</Form.Label>
              <InputGroup className="mb-3">
                <Button
                  variant="primary"
                  onClick={(e) => {
                    let str =
                      inputDatas[0]?.yil +
                      " Eğitim Öğretim Yılı " +
                      inputDatas[0]?.donem +
                      ". Dönemi";
                    //formik.values.baslik = str;
                    eventHandle("baslik", str);
                  }}
                >
                  Başlık Oluştur
                </Button>
                <input
                  className="form-control"
                  id="baslik"
                  name="baslik"
                  type="text"
                  placeholder="Başlık oluşturun"
                  onChange={(e) => {
                    eventHandle("baslik", e.target.value);
                  }}
                  value={inputDatas[0]?.baslik}
                // onChange={(e)=>{formik.handleChange(e);console.log(formik.dirty);}}
                //value={formik.values.baslik}
                />
                <span className="validation-error">
                  {/* {formik.errors.baslik } */}
                </span>
              </InputGroup>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Açıklama</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => {
                  eventHandle("aciklama", e.target.value);
                }}
                value={inputDatas[0]?.aciklama}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Check
                  id="cdk"
                  type="checkbox"
                  onChange={(e) => {
                    eventHandle(
                      "calismaDonemiKitlensin",
                      e.target.checked ? 1 : 0
                    );
                  }}
                  defaultChecked={
                    inputDatas[0]?.calismaDonemiKitlensin === 1 ? true : false
                  }
                />
                <Form.Label htmlFor="cdk">
                  <span>&nbsp;</span>Çalışma dönemi kilitlensin
                </Form.Label>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Check
                  id="odpg"
                  type="checkbox"
                  onChange={(e) => {
                    eventHandle(
                      "ogretmenlerDersPrograminiGorebilsin",
                      e.target.checked ? 1 : 0
                    );
                  }}
                  defaultChecked={
                    inputDatas[0]?.ogretmenlerDersPrograminiGorebilsin === 1
                      ? true
                      : false
                  }
                />
                <Form.Label htmlFor="odpg">
                  <span>&nbsp;</span>Öğretmenler ders programını görebilir
                </Form.Label>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Check
                  id="vcd"
                  type="checkbox"
                  onChange={(e) => {
                    eventHandle(
                      "varsayilanCalismaDonemiOlsun",
                      e.target.checked ? 1 : 0
                    );
                  }}
                  defaultChecked={
                    inputDatas[0]?.varsayilanCalismaDonemiOlsun === 1
                      ? true
                      : false
                  }
                />
                <Form.Label htmlFor="vcd">
                  <span>&nbsp;</span>Varsayılan çalışma dönemi olsun
                </Form.Label>
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {btnDuzenle === -1 ? (
            ""
          ) : (
            <Button
              variant="primary"
              onClick={() => guncelleHandle(btnDuzenle)}
            >
              Güncelle
            </Button>
          )}
          <Button variant="primary" onClick={kaydet}>
            Kaydet
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="xl"
        show={showExcel}
        backdrop="static"
        onHide={handleCloseExcel}
      >
        <Modal.Header closeButton>
          <Modal.Title>Excel Toplu Veri Girişi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <a href="/sablon.xlsx" target={"_blank"} download>
                Şablonu indirmek için tıklayın
              </a>
              <hr></hr>
              <Myzone />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* {btnDuzenle === -1 ? (
            ""
          ) : (
            <Button
              variant="primary"
              onClick={() => guncelleHandle(btnDuzenle)}
            >
              Güncelle
            </Button>
          )}
          <Button variant="primary" onClick={kaydet}>
            Kaydet
          </Button> */}
          <Button variant="secondary" onClick={handleCloseExcel}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DersProgramListesi;

function Myzone(props) {

  const onDrop = useCallback((acceptedFiles) => {
    if (
      !(
        acceptedFiles[0].type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        acceptedFiles[0].type == "application/vnd.ms-excel"
      )
    ) {
      console.log(acceptedFiles[0].type)
      alert("Sadece excel formatında dosya yükleyebilirsiniz!");
      return;
    }
    console.log(223)
    var data = new FormData();
    data.append("file", acceptedFiles[0]);
    fetch(variables.API_URL + "Employee/SaveFile", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((success) => console.log(success))
      .catch((error) => console.log(error));
  }, []);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <div className="">
      <div {...getRootProps({ className: "dropzone p-5 bg-alice" })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Dosyanızı bu alana bırakın ...</p>
        ) : (
          <p className="text-center">Düzenlenmiş şablon dosyanızı bu alana bırakın yada <a href="#">tıklayıp</a> dosyanızı seçin</p>
        )}
        {files}
      </div>
    </div>
  );
}
