import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import {
  BsFillXSquareFill,
  BsFillCheckSquareFill,
  BsFillPencilFill,
  BsPlusCircleFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import * as yup from "yup";

const SiniflarVeSubeler = (props) => {
  const localTablo = secureLocalStorage.getItem("SiniflarveSubeler");
  const [btnDuzenle, setBtnDuzenle] = useState(-1);
  const seviyeler = [
    "12",
    "11",
    "10",
    "9",
    "Hazırlık",
    "8",
    "7",
    "6",
    "5",
    "4",
    "3",
    "2",
    "1",
  ];
  const initliste = {
    sinif: 12,
    sinifAdi: "Sınıf adı",
    listeAdi: "ListeAdı",
    sinifMevcudu: 0,
    toplamDersSaati: 0,
  };

  const [liste, setliste] = useState(initliste);
  const [tablo, settablo] = useState(localTablo ?? []);
  const [seviyeModalShow, setSeviyeModalShow] = useState(false);

  const handleliste = (propname, val) => {
    let dat = structuredClone(liste);
    dat[propname] = val ? val : "";
    setliste(dat);
  };
  const guncelleHandle = (key) => {
    const guncelle = structuredClone(tablo);
    guncelle[key] = liste;
    settablo(guncelle);
    setSeviyeModalShow(false);
    mesaj("success", "Güncelleme işlemi başarılı!");
  };
  const silHandle = (key) => {
    const sil = structuredClone(tablo);
    sil.splice(key, 1);
    settablo(sil);
    secureLocalStorage.setItem("SiniflarveSubeler", sil);
    mesaj("success", "Silme işlemi başarılı");
  };
  const ekleHandle = () => {
    var li = structuredClone(tablo);
    li.push(liste);
    settablo(li);
    secureLocalStorage.setItem("SiniflarveSubeler", li);
    mesaj("success", "Yeni kayıt eklendi!");
    setSeviyeModalShow(false);
  }
  const duzenleHandle = (key) => {
    let obj = structuredClone(tablo[key]);
    setliste(obj);
    setSeviyeModalShow(true);
    setBtnDuzenle(key);
    //const duzenle = structuredClone(data);
    //setInputDatas([duzenle[key]]);
    //setShow(true);
  };
  useEffect(() => {
    props.fnVal("SiniflarVeSubeler", tablo.length);
  }, [tablo]);
  return (
    <>
      {/* <TContainer /> */}

      <div className="card-body bg-light">
        <hr></hr>
        <Ganimated isVisible={true} className={tablo.length == 0 ? "infinite " : ""}>
          <Button
            variant="primary"
            onClick={() => {
              setSeviyeModalShow(true);
            }}
          >
            Sınıf ekle
          </Button>
        </Ganimated>


        <hr></hr>
        <div className="col-12">
          <table className="table table-hovered">
            <thead>
              <tr>
                <th>#</th>
                <th>Sıra</th>
                <th>Sınıf</th>
                <th>Sınıf Adı</th>
                <th>Liste Adı</th>
                <th>Sınıf Mevcudu</th>
                <th>Ders Sayısı</th>
                <th>Alt Grup</th>
              </tr>
            </thead>
            <tbody>
              {tablo.length > 0 ? (
                tablo.map((elem, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span
                          className="handCursor"
                          data-tip="Sil"
                          onClick={() => {
                            window.confirm(
                              "Silmek istediğinizden emin misiniz?"
                            )
                              ? silHandle(index)
                              : mesaj("success", "İptal ettiniz");
                          }}
                        >
                          <BsFillXSquareFill color="red" />
                        </span>
                        <span>&nbsp;</span>
                        <span
                          className="handCursor"
                          data-tip="Düzenle"
                          onClick={() => {
                            duzenleHandle(index);
                          }}
                        >
                          <BsFillPencilFill color="green" />
                        </span>
                        <span>&nbsp;</span>
                      </td>
                      <td>{index + 1}</td>
                      <td>{elem.sinif}</td>
                      <td>{elem.sinifAdi}</td>
                      <td>{elem.listeAdi}</td>
                      <td>{elem.sinifMevcudu}</td>
                      <td>{elem.toplamDersSaati}</td>
                      <td>
                        <BsPlusCircleFill
                          color="#2569c3"
                          className="handCursor "
                          onClick={() => { }}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>Kayıt Ekleyiniz</td>
                  <td>Kayıt Ekleyiniz</td>
                  <td>Kayıt Ekleyiniz</td>
                  <td>Kayıt Ekleyiniz</td>
                  <td>Kayıt Ekleyiniz</td>
                  <td>Kayıt Ekleyiniz</td>
                  <td>Kayıt Ekleyiniz</td>
                </tr>
              )}
            </tbody>
          </table>
          {tablo ? tablo.length + " adet eklediniz." : ""}
        </div>
      </div>
      <Modal
        size="xl"
        show={seviyeModalShow}
        backdrop="static"
        onHide={() => {
          setSeviyeModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sınıf ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="was-validated">
            <div className="form-group mb-3">
              <label>Sınıf</label>
              <select
                className="form-control"
                value={liste.sinif}
                onChange={(e) => {
                  handleliste("sinif", e.target.value);
                }}
              >
                {seviyeler.map((o, index) => {
                  return (
                    <option key={index} value={o}>
                      {o}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3">
              <label>Sınıf Adı</label>
              <input
                type="text"
                className="form-control"
                value={liste.sinifAdi}
                onFocus={(e) => {
                  e.target.select();
                }}
                required
                onChange={(e) => {
                  handleliste("sinifAdi", e.target.value);
                }}
              />
            </div>
            <div className="form-group mb-3">
              <label>Liste Adı</label>
              <input
                type="text"
                className="form-control"
                value={liste.listeAdi}
                onFocus={(e) => {
                  e.target.select();
                }}
                required
                onChange={(e) => {
                  handleliste("listeAdi", e.target.value);
                }}
              />
            </div>
            <div className="form-group mb-3">
              <label>Sınıf Mevcudu</label>
              <input
                type="number"
                className="form-control"
                value={liste.sinifMevcudu}
                onFocus={(e) => {
                  e.target.select();
                }}
                required
                onChange={(e) => {
                  handleliste("sinifMevcudu", e.target.value);
                }}
              />
            </div>
            <div className="form-group mb-3">
              <label>Toplam Ders Saati</label>
              <input
                type="number"
                className="form-control"
                value={liste.toplamDersSaati}
                onFocus={(e) => {
                  e.target.select();
                }}
                required
                onChange={(e) => {
                  handleliste("toplamDersSaati", e.target.value);
                }}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {btnDuzenle === -1 ? (
            ""
          ) : (
            <Button
              variant="primary"
              onClick={() => guncelleHandle(btnDuzenle)}
            //onClick={() => {}}
            >
              Güncelle
            </Button>
          )}
          <Button
            variant="primary"
            onClick={() => {
              ekleHandle()
            }}
          >
            {/* fnSeviyeEkle */}
            Sınıf Ekle
          </Button>
          {/* <Button variant="secondary" onClick={handleClose}> */}
          <Button
            variant="secondary"
            onClick={() => {
              setSeviyeModalShow(false);
            }}
          >
            Kapat
          </Button>
          <br />
        </Modal.Footer>
      </Modal>

      {props.children}
    </>
  );
};
export default SiniflarVeSubeler;
