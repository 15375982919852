import React from "react";
const NavItem = (props) => {
  return (
    <li className="nav-item">
      <a
        // id={"nav-CalismaDonemleri"}
        id={props.aId}
        data-it={props.dataIt}
        // className="nav-link fw-semi-bold active"
        className={`nav-link fw-semi-bold ${props.aCss}`}
        href={props.aHref}
        data-bs-toggle="tab"
        data-wizard-step="data-wizard-step"
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <span className="nav-item-icon align-middle">
              {/* {props.children} */}
              {props.icon}
            </span>
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{props.baslik}</span>
      </a>
    </li>
  );
};

export default NavItem;
