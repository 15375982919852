import React, { useState } from "react";
import "../../src/assets/css/side.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { redirect } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
// Example starter JavaScript for disabling form submissions if there are invalid fields

window.sbmt = (selector) => {
  // const forms = document.querySelectorAll(selector);
  // console.log(forms);
  // Array.prototype.slice.call(forms).forEach((form) => {
  //   if (!form.checkValidity()) {
  //     form.classList.add("was-validated");
  //     return false;
  //   } else {
  //     form.classList.add("was-validated");
  //     return true;
  //   }
  // });
};
function Header() {
  const [open, setOpen] = useState(0);
  return (
    <>
      <Navbar bg="light" variant="light">
        {/* <span
          className="sideBarOpen"
          onClick={() => {
            setOpen(220);
          }}
        >
          &#9776;
        </span> */}
        <Container>
          <Navbar.Brand href="/">Dağıtmatik</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="https://vipkurum.com/">Vipkurum</Nav.Link>
          </Nav>
        </Container>
        <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
          <li className="nav-item dropdown">
            <a
              className="nav-link pe-0 ps-2"
              id="navbarDropdownUser"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="avatar avatar-xl">
                <img
                  className="rounded-circle"
                  src="assets/img/team/avatar.png"
                  alt=""
                />
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
              aria-labelledby="navbarDropdownUser"
            >
              <div className="bg-white dark__bg-1000 rounded-2 py-2">
                <a className="dropdown-item" href="/">
                  Profile &amp; account
                </a>
                <a className="dropdown-item" href="/">
                  Feedback
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="/">
                  Settings
                </a>
                <a
                  className="dropdown-item"
                  href="/"
                  onClick={() => {
                    secureLocalStorage.removeItem("Oturum");
                  }}
                >
                  Çıkış
                </a>
              </div>
            </div>
          </li>
        </ul>
      </Navbar>
    </>
  );
}

export default Header;

// <>
//         <div id="mySidenav" style={{ width: open }} className="sidenav">
//           <a
//             href="#"
//             className="closebtn"
//             onClick={() => {
//               setOpen(0);
//             }}
//           >
//             &times;
//           </a>
//           <a href="/DersProgrami">Ders Programı</a>
//           <a href="/Kayit">
//             <i className=""> </i> Kaydol
//           </a>
//           <a href="/Giris">
//             <i className=""></i>Giriş
//           </a>
//           <a
//             href="/"
//             onClick={() => {
//               secureLocalStorage.setItem("Oturum", null);
//             }}
//           >
//             <i className=""></i>Çıkış
//           </a>
//         </div>

//       </>
