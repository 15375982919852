import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";

const R14 = (props) => { 
  const ZamanKurallariR = secureLocalStorage.getItem("ZamanKurallariR14") ?? [];
  const [show, setShow] = useState(true);
  const [form, setForm] = useState({
    ogretmen: "",
    etiket: "",
    maxCalisma: "",
    kuralaUyma: "",
  });
  const opt = [{ label: "Seçin ", value: "" }];

  const handleClose = () => {
    props.sendModalKapat();
    setShow(false);
  };

  const eventHandle = (propname, val, index = 0, element) => {
    let dat = structuredClone(form);
    dat[propname] = val ?? ""; 
    setForm(dat);
    secureLocalStorage.setItem("ZamanKurallariR14", dat);
  };
  useEffect(() => {
     
  });
   
  const degisti = (e) => {
    // setselectSelected(e?.value);
    // if (e?.value == "") {
    //   alert("Öğretmen Seçiniz");
    //   return;
    // } 
  };
  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };

  return (
    <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalYazi} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
           
          <Col>
            <Form.Group>
              <Form.Label>Maximum boşluk</Form.Label>
              <Select
                options={[
                  {label:"0",value:"0"},
                  {label:"1",value:"1"},
                  {label:"2",value:"2"},
                  {label:"3",value:"3"},
                  {label:"4",value:"4"},
                  {label:"5",value:"5"},
                  {label:"6",value:"6"},
                  {label:"7",value:"7"},
                  {label:"8",value:"8"}
                ]}
                onChange={(e) => {
                  degisti(e);
                }}
                defaultValue={opt[0]}
              />
           
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" onClick={kaydet}>
          Kaydet
        </Button> */}
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default R14;
