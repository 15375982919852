import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { setMe, kuralaUymaData } from "../../helpers/generic";

const R101 = (props) => {
  const ZamanKurallariR = secureLocalStorage.getItem("ZamanKurallariR101");
  const SiniflarveSubeler = secureLocalStorage.getItem("SiniflarveSubeler");
  const initiall = {
    sinifAdi: SiniflarveSubeler?.length> 0 ? SiniflarveSubeler[0].sinifAdi :0,
    kuralaUyma: 100,
    kuralAktif: true,
  };
  const [show, setShow] = useState(true);
  const [form, setForm] = useState(ZamanKurallariR ?? initiall);

  const handleClose = () => {
    props.sendModalKapat();
    setShow(false);
  };

  useEffect(() => {
    if (!SiniflarveSubeler?.length > 0) {
      alert(
        'Bu kuralı oluşturabilmek için "Siniflar ve Subeler" eklemeniz gerekmektedir!'
      );
      handleClose();
    }
  });

  const kaydet = () => {
    secureLocalStorage.setItem("ZamanKurallariR101", form);
    handleClose();
  };
  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };

  const getSiniflarveSubeler = () => {
    let dt = [];
    SiniflarveSubeler?.forEach((ele) => { 
      dt.push({ target: { name: "sinif" }, label: ele.sinifAdi, value:ele.sinifAdi })
    });
    return dt;
  };
   
  const maxGunData = [
    { target: { name: "maxGun" }, label: "0", value: "0" },
    { target: { name: "maxGun" }, label: "1", value: "1" },
    { target: { name: "maxGun" }, label: "2", value: "2" },
    { target: { name: "maxGun" }, label: "3", value: "3" },
    { target: { name: "maxGun" }, label: "4", value: "4" },
    { target: { name: "maxGun" }, label: "5", value: "5" },
  ];
  return (
    <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalYazi} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Sınıf </Form.Label>
              <Select
                options={getSiniflarveSubeler()}
                defaultValue={
                  getSiniflarveSubeler()?.filter((C) => C.value == form?.sinifAdi)[0]
                }
                name={"sinifAdi"}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Label>Maksimum gün </Form.Label>
              <Select
                options={maxGunData}
                defaultValue={
                  maxGunData.filter((C) => C.value == form?.maxGun)[0]
                }
                name={"maxGun"}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Label>Kurala uyma oranı</Form.Label>
              <Select
                options={kuralaUymaData}
                defaultValue={
                  kuralaUymaData.filter((C) => C.value == form?.kuralaUyma)[0]
                }
                name={"kuralaUyma"}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Check
                type={"switch"}
                id={`kuralAktif`}
                label={`Kural Aktif`}
                name={"kuralAktif"}
                checked={form.kuralAktif}
                onChange={(e) => setForm(setMe(form, e))}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={kaydet}>
          Kaydet
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default R101;
