import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import {
  BsFillXSquareFill,
  BsFillCheckSquareFill,
  BsFillPencilFill,
  BsFillPersonLinesFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";
import * as yup from "yup";

export default function Branslar(props) {
  const bransListe = [
    {
      bransKod: "ADA",
      brans: "ADALET",
    },
    {
      bransKod: "BİLTEK",
      brans: "BİLİŞİM TEKNOLOJİLERİ",
    },
    {
      bransKod: "ALM",
      brans: "ALMANCA",
    },
  ];
  const liste = [
    {
      bransKod: "",
      brans: "",
      slct: "",
    },
  ];
  const localListe = secureLocalStorage.getItem("Branslar");
  const handleShow = () => {
    setModalListe(liste);
    setBtnDuzenle(-1);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState(localListe ?? []);
  const [btnDuzenle, setBtnDuzenle] = useState(-1);
  const [modalListe, setModalListe] = useState(liste);
  const selectChange = (e) => {
    let li2 = structuredClone(modalListe[0]);
    li2.slct = e.target.value;
    li2.brans = e.target.options[e.target.selectedIndex].dataset.brans;
    li2.bransKod = e.target.options[e.target.selectedIndex].dataset.branskod;
    //modalListe[0].brans
    //console.log(li2);
    setModalListe([li2]);
  };
  const eventHandle = (propname, val, index = 0, element) => {
    // let dat = structuredClone(bransListe);
    let dat = structuredClone(modalListe);
    dat[index][propname] = val ?? "";
    //console.log(propname, val);
    console.log(dat);
    setModalListe(dat);
  };
  const guncelleHandle = (key) => {
    const guncelle = structuredClone(data);
    guncelle[key] = modalListe[0];
    //console.log(guncelle);
    setData(guncelle);
    setShow(false);
    mesaj("success", "Güncelleme işlemi başarılı!");
  };
  const kaydet = () => {
    let dat = structuredClone(data);
    dat.push(modalListe[0]);
    setData(dat);
    secureLocalStorage.setItem("Branslar", dat);
    setShow(false);
    mesaj("success", "Yeni kayıt eklendi!");
  };
  const silHandle = (key) => {
    const sil = structuredClone(data);
    sil.splice(key, 1);
    setData(sil);
    secureLocalStorage.setItem("Branslar", sil);
    mesaj("success", "Silme işlemi başarılı");
  };
  const duzenleHandle = (key) => {
    setBtnDuzenle(key);
    const duzenle = structuredClone(data);
    setModalListe([duzenle[key]]);
    setShow(true);
  };
  const getData=()=>{
    fetch('https://localhost:7022/api/brans')
    .then((res)=>res.json())
    .then(json =>{setData(json);console.log(json)})// console.log(json))
    .catch(err => console.log(err));
  }

  useEffect(() => { 
    props.fnVal("Branslar", data?.length);
    //getData();

  }, []);

  return (
    <>
      {/* <pre>{JSON.stringify(localListe??[])}</pre> */}
      {/* <TContainer /> */}
      {/* <h2 className="card-header">Branşlar</h2> */}
      <div className="card-body bg-light">
        <hr></hr>
        <Ganimated isVisible={true} className={data?.length == 0 ? "infinite " : ""}>
          <Button variant="primary" onClick={handleShow}>
            Branş Ekle
          </Button>
        </Ganimated>
        <hr></hr>
        <Table hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Branş</th>
              <th>Branş Kodu</th>
              <th>Öğretmen Sayısı</th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              data.map((el, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span
                        className="handCursor"
                        data-tip="Sil"
                        onClick={() => {
                          window.confirm("Silmek istediğinizden emin misiniz?")
                            ? silHandle(index)
                            : mesaj("success", "İptal ettiniz");
                        }}
                      >
                        <BsFillXSquareFill color="red" />
                      </span>
                      <span>&nbsp;</span>
                      <span
                        className="handCursor"
                        data-tip="Düzenle"
                        onClick={(e) => {
                          // setShow(true);
                          // setBrans(el.brans);
                          // setBransKod(el.bransKod);
                          duzenleHandle(index);
                        }}
                      >
                        <BsFillPencilFill color="green" />
                      </span>
                    </td>
                    <td>{el.brans ??el.bransAd}</td>
                    <td>{el.bransKod}</td>
                    <td>???</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>Kayıt Ekleyiniz</td>
                <td>Kayıt Ekleyiniz</td>
                <td>Kayıt Ekleyiniz</td>
                <td>Kayıt Ekleyiniz</td>
              </tr>
            )}
          </tbody>
        </Table>
        {data ? data?.length + " adet eklediniz." : ""}

        <hr></hr>
      </div>

      <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Branş Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="was-validated">
            <Form.Group className="mb-3">
              <Form.Label>Branş</Form.Label>
              <br />
              <select
                className="dropdown w100 form-control"
                value={modalListe[0].slct}
                required
                onChange={(e) => {
                  selectChange(e);
                }}
              >
                <option value="">Seçiniz</option>
                {bransListe.map((o, index) => (
                  <option
                    key={index}
                    data-brans={o.brans}
                    data-branskod={o.bransKod}
                    value={o.brans}
                  >
                    {o.brans}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Branş Adı</Form.Label>
              <Form.Control
                type="text"
                required
                value={modalListe[0].brans}
                onChange={(e) => eventHandle("brans", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Branş Kodu</Form.Label>
              <Form.Control
                type="text"
                required
                value={modalListe[0].bransKod}
                onChange={(e) => eventHandle("bransKod", e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {btnDuzenle === -1 ? (
            ""
          ) : (
            <Button
              variant="primary"
              onClick={(e) => guncelleHandle(btnDuzenle)}
            >
              Güncelle
            </Button>
          )}
          <Button variant="primary" onClick={kaydet}>
            Kaydet
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
          <br />
        </Modal.Footer>
      </Modal>
    </>
  );
}
