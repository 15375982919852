import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import {
    BsFillXSquareFill,
    BsFillCheckSquareFill,
    BsFillPencilFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import * as yup from "yup";

const DersAtamalari = (props) => {
    const liste_dersPaketAdi = ["Ders Paket Adi1", "Ders Paket Adi2"];
    const liste_dersTuru = ["Ortak Dersler", "Seçmeli Dersler"];
    const liste_sinifSeviyesi = [
        "12",
        "11",
        "10",
        "9",
        "Hazırlık",
        "8",
        "7",
        "6",
        "5",
        "4",
        "3",
        "2",
        "1",
    ];
    const liste_dagitim = ["Tek Blok", "Çift Blok"];
    const liste_ilgiliBrans = ["Bilişim Teknolojileri", "Felsefe", "İngilizce"];

    const liste = [
        {
            dersPaketAdi: "Ders Paket Adi1",
            dersTuru: "Ortak Dersler",
            sinifSeviyesi: "12",
            dersKodu: "DK",
            dersAdi: "Ders Adı",
            dersSaati: "2",
            dagitim: "Tek Blok",
            ilgiliBrans: "Bilişim Teknolojileri",
        },
    ];
    const localListe = secureLocalStorage.getItem("DersAtamalari");

    const [show, setShow] = useState(false);
    const [btnDuzenle, setBtnDuzenle] = useState(-1);
    const [modalListe, setModalListe] = useState(liste);
    const [data, setData] = useState(localListe ?? []);
    const [selectedOption, setSelectedOption] = useState(liste[0].bransKod);

    const handleShow = () => {
        setModalListe(liste);
        setBtnDuzenle(-1);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setBtnDuzenle(-1);
    };
    const silHandle = (key) => {
        const sil = structuredClone(data);
        sil.splice(key, 1);
        setData(sil);
        secureLocalStorage.setItem("DersAtamalari", sil);
        mesaj("success", "Silme işlemi başarılı");
    };
    const eventHandle = (propname, val, index = 0, element) => {
        let dat = structuredClone(modalListe);
        dat[index][propname] = val ? val : "";
        setModalListe(dat);
    };
    const kaydet = () => {
        let dat = structuredClone(data);
        dat.push(modalListe[0]);
        setData(dat);
        secureLocalStorage.setItem("DersAtamalari", dat);
        setShow(false);
        mesaj("success", "Yeni kayıt eklendi!");
    };
    const duzenleHandle = (key) => {
        setBtnDuzenle(key);
        const duzenle = structuredClone(data);
        setModalListe([duzenle[key]]);
        setShow(true);
    };
    const guncelleHandle = (key) => {
        setBtnDuzenle(key);
        const guncelle = structuredClone(data);
        guncelle[key] = modalListe[0];
        setData(guncelle);
        secureLocalStorage.setItem("DersAtamalari", guncelle);
        setShow(false);
        mesaj("success", "Güncelleme işlemi başarılı!");
    };
  
    useEffect(() => {
        //setData(localListe ?? []);
        secureLocalStorage.setItem("DersAtamalari", data);
        if ((data?.length > 0 || data == null)) {
            props.fnVal("DersAtamalari", 1);
        } else {
            props.fnVal("DersAtamalari", 0);
        }
    }, [data]);
    return (
        <> 
            <div className="card-body bg-light">
                <hr></hr>
                <Ganimated
                    isVisible={true}
                    className={data?.length > 0 ? "ff " : "infinite"}
                >
                    <Button variant="primary" onClick={handleShow}>
                        Ders Atama
                    </Button>
                </Ganimated>

                <hr></hr>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Sıra</th>
                            <th>Ders Adı</th>
                            <th>Ders Kodu</th>
                            <th>Ders Saati</th>
                            <th>Dağıtım</th>
                            <th>Sınıf Seviye</th>
                            <th>Ders Türü</th>
                            <th>İlgili Branş</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data ? (
                            data.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <span
                                                className="handCursor"
                                                data-tip="Sil"
                                                onClick={() => {
                                                    window.confirm("Silmek istediğinizden emin misiniz?")
                                                        ? silHandle(index)
                                                        : mesaj("success", "İptal ettiniz");
                                                }}
                                            >
                                                <BsFillXSquareFill color="red" />
                                            </span>
                                            <span>&nbsp;</span>
                                            <span
                                                className="handCursor"
                                                data-tip="Düzenle"
                                                onClick={() => { 
                                                    duzenleHandle(index);
                                                }}
                                            >
                                                <BsFillPencilFill color="green" />
                                            </span>
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>{el.dersAdi}</td>
                                        <td>{el.dersKodu}</td>
                                        <td>{el.dersSaati}</td>
                                        <td>{el.dagitim}</td>
                                        <td>{el.sinifSeviyesi}</td>
                                        <td>{el.dersTuru}</td>
                                        <td>{el.ilgiliBrans}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>yükleniyor</tr>
                        )}
                    </tbody>
                </Table>
                {data ? data?.length + " adet eklediniz." : ""}
                <hr></hr>
            </div>

            <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ders Atama</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="was-validated">
                        <Form.Group className="mb-3">
                            <Form.Label>Ders Paket Adı</Form.Label>
                            <br />
                            <select
                                className="form-control"
                                value={modalListe[0].dersPaketAdi}
                                // value={selectedOption}
                                required
                                onChange={(e) => {
                                    eventHandle("dersPaketAdi", e.target.value);
                                    //setSelectedOption(e.target.value)
                                }}
                            >
                                {
                                    //bransListe ? (
                                    liste_dersPaketAdi.map((o, index) => {
                                        return (
                                            <option key={index} value={o}>
                                                {o}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ders Türü</Form.Label>
                            <br />
                            <select
                                className="form-control"
                                value={modalListe[0].dersTuru}
                                required
                                onChange={(e) => {
                                    eventHandle("dersTuru", e.target.value);
                                    //setSelectedOption(e.target.value)
                                }}
                            >
                                {
                                    //bransListe ? (
                                    liste_dersTuru.map((o, index) => {
                                        return (
                                            <option key={index} value={o}>
                                                {o}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Sınıf Seviyesi</Form.Label>
                            <br />
                            <select
                                className="form-control"
                                value={modalListe[0].sinifSeviyesi}
                                required
                                onChange={(e) => {
                                    eventHandle("sinifSeviyesi", e.target.value);
                                    //setSelectedOption(e.target.value)
                                }}
                            >
                                {
                                    //bransListe ? (
                                    liste_sinifSeviyesi.map((o, index) => {
                                        return (
                                            <option key={index} value={o}>
                                                {o}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ders Kodu</Form.Label>
                            <Form.Control
                                type="text"
                                value={modalListe[0].dersKodu}
                                onFocus={(e) => e.target.select()}
                                required
                                onChange={(e) => eventHandle("dersKodu", e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ders Adı</Form.Label>
                            <Form.Control
                                type="text"
                                value={modalListe[0].dersAdi}
                                onFocus={(e) => e.target.select()}
                                required
                                onChange={(e) => eventHandle("dersAdi", e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ders Saati</Form.Label>
                            <Form.Control
                                type="number"
                                value={modalListe[0].dersSaati}
                                onFocus={(e) => e.target.select()}
                                required
                                onChange={(e) => eventHandle("dersSaati", e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Dağıtım</Form.Label>
                            <br />
                            <select
                                className="form-control"
                                value={modalListe[0].dagitim}
                                required
                                onChange={(e) => {
                                    eventHandle("dagitim", e.target.value);
                                    //setSelectedOption(e.target.value)
                                }}
                            >
                                {
                                    //bransListe ? (
                                    liste_dagitim.map((o, index) => {
                                        return (
                                            <option key={index} value={o}>
                                                {o}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>İlgili Branş</Form.Label>
                            <br />
                            <select
                                className="form-control"
                                value={modalListe[0].ilgiliBrans}
                                required
                                onChange={(e) => {
                                    eventHandle("ilgiliBrans", e.target.value);
                                    //setSelectedOption(e.target.value)
                                }}
                            >
                                {
                                    //bransListe ? (
                                    liste_ilgiliBrans.map((o, index) => {
                                        return (
                                            <option key={index} value={o}>
                                                {o}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {btnDuzenle === -1 ? (
                        ""
                    ) : (
                        <Button
                            variant="primary"
                            onClick={(e) => guncelleHandle(btnDuzenle)}
                        >
                            Güncelle
                        </Button>
                    )}
                    <Button variant="primary" onClick={kaydet}>
                        Kaydet
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Kapat
                    </Button>
                    <br />
                </Modal.Footer>
            </Modal>
            {props.children}
        </>
    );
};
export default DersAtamalari;
