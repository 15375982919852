import React, { useState, useEffect ,useRef} from "react";
import { Modal,  Button } from "react-bootstrap";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";

const R1 = (props) => {
  const HaftalikTabloRef = useRef();
  const Ogretmenler = secureLocalStorage.getItem("Ogretmenler");
  const DersSaatleri = secureLocalStorage.getItem("DersSaatleri")??[];
  const [show, setShow] = useState(true);
  const [selectSelected, setselectSelected] = useState([]); 
  const opt = [{ label: "Seçin yada arayın", value: "" }];

  const handleClose = () => {
    props.sendModalKapat(); 
    setShow(false);
  };
 
  Ogretmenler?.forEach((element) => {
    opt.push({  label: element.ad + " " + element.soyad,  value: element.ad + " " + element.soyad,  });
  });
  Ogretmenler?.length>0 ?? alert("Öğretmen kaydı yapınız.");
  Ogretmenler ?? opt.push({ label: "Öğretmen Ekleyin!", value: "Ekle" });
  
   const tabloyuTemizle=()=>{
    let cells = HaftalikTabloRef?.current?.querySelectorAll(`.bgDarkRed`);
    cells?.forEach(element => {
      element.classList?.remove('bgDarkRed')
    });
   }
  const degisti = (e) => {
    setselectSelected (e?.value)
    if(e?.value==""){ alert("Öğretmen Seçiniz"); return; }
    tabloyuTemizle(); 
  };
  const setBg=(event)=>{ 
    let xdata=event.target.dataset.name;
    let pushh ={selected:selectSelected,data:xdata}
    let storage = secureLocalStorage.getItem("ZamanKurallariR1")??[]; 
    if(event.target.classList.contains('bgDarkRed')){
      event.target.classList.remove('bgDarkRed')
      storage.forEach((element,ind) => {
        if(element.selected == selectSelected && element.data == xdata){storage.splice(ind,1)}
      });
    }else{
      event.target.classList.add('bgDarkRed')
      storage?.push(pushh)
    }
    secureLocalStorage.setItem("ZamanKurallariR1",storage)
  }
  const fillTable=()=>{
    let storage = secureLocalStorage.getItem("ZamanKurallariR1")??[]; 
    storage?.forEach(element => {
      if(element.selected==selectSelected){
          let cell = HaftalikTabloRef?.current?.querySelector(`[data-name="${element.data}"]`);
          cell?.classList.remove('bgDarkRed');cell?.classList.add('bgDarkRed');
      }
    });
  }
  useEffect(()=>{
    if(! DersSaatleri?.length > 0){ alert('Bu kuralı oluşturabilmek için "Ders Saatleri" eklemeniz gerekmektedir!');handleClose() }
    if(! Ogretmenler?.length > 0){ alert('Bu kuralı oluşturabilmek için "Öğretmen" eklemeniz gerekmektedir!');handleClose() }
    fillTable();
  })
  return (
    <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalYazi} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select options={opt} onChange={(e)=>{degisti(e)}} defaultValue={opt[0]} />
        <br />
        {
          selectSelected?.length>0 ?
          <div 
          ref={HaftalikTabloRef} 
          >
          <div className="row "><div className="col border"></div><div className="col border">Pazartesi</div><div className="col border">Salı</div><div className="col border">Çarşamba</div><div className="col border">Perşembe</div><div className="col border">Cuma</div><div className="col border">Cumartesi</div><div className="col border">Pazar</div></div>

            {
              DersSaatleri.map((el,index)=> (
                <div key={index} className="row "><div className="col border">{el.bas}-{el.bit}</div><div data-name="1_1" onClick={setBg} className="col border"></div><div data-name="1_2" onClick={setBg} className="col border"></div><div  data-name="1_3" onClick={setBg} className="col border"></div><div  data-name="1_4" onClick={setBg} className="col border"></div><div  data-name="1_5" onClick={setBg} className="col border"></div><div  data-name="1_6" onClick={setBg} className="col border"></div><div  data-name="1_7" onClick={setBg} className="col border"></div></div>
              ))
            }
          {/* <div className="row "><div className="col border">08:50-09:30</div><div data-name="2_1" onClick={setBg} className="col border"></div><div data-name="2_2" onClick={setBg} className="col border"></div><div  data-name="2_3" onClick={setBg} className="col border"></div><div  data-name="2_4" onClick={setBg} className="col border"></div><div  data-name="2_5" onClick={setBg} className="col border"></div><div  data-name="2_6" onClick={setBg} className="col border"></div><div  data-name="2_7" onClick={setBg} className="col border"></div></div>
          <div className="row "><div className="col border">09:40-10:20</div><div data-name="3_1" onClick={setBg} className="col border"></div><div data-name="3_2" onClick={setBg} className="col border"></div><div  data-name="3_3" onClick={setBg} className="col border"></div><div  data-name="3_4" onClick={setBg} className="col border"></div><div  data-name="3_5" onClick={setBg} className="col border"></div><div  data-name="3_6" onClick={setBg} className="col border"></div><div  data-name="3_7" onClick={setBg} className="col border"></div></div>
          <div className="row "><div className="col border">10:30-11:10</div><div data-name="4_1" onClick={setBg} className="col border"></div><div data-name="4_2" onClick={setBg} className="col border"></div><div  data-name="4_3" onClick={setBg} className="col border"></div><div  data-name="4_4" onClick={setBg} className="col border"></div><div  data-name="4_5" onClick={setBg} className="col border"></div><div  data-name="4_6" onClick={setBg} className="col border"></div><div  data-name="4_7" onClick={setBg} className="col border"></div></div>
          <div className="row "><div className="col border">11:20-12:00</div><div data-name="5_1" onClick={setBg} className="col border"></div><div data-name="5_2" onClick={setBg} className="col border"></div><div  data-name="5_3" onClick={setBg} className="col border"></div><div  data-name="5_4" onClick={setBg} className="col border"></div><div  data-name="5_5" onClick={setBg} className="col border"></div><div  data-name="5_6" onClick={setBg} className="col border"></div><div  data-name="5_7" onClick={setBg} className="col border"></div></div>
          <div className="row "><div className="col border">12:00-12:30</div><div data-name="6_1" onClick={setBg} className="col border"></div><div data-name="6_2" onClick={setBg} className="col border"></div><div  data-name="6_3" onClick={setBg} className="col border"></div><div  data-name="6_4" onClick={setBg} className="col border"></div><div  data-name="6_5" onClick={setBg} className="col border"></div><div  data-name="6_6" onClick={setBg} className="col border"></div><div  data-name="6_7" onClick={setBg} className="col border"></div></div>
          <div className="row "><div className="col border">12:40-</div><div data-name="7_1" onClick={setBg} className="col border"></div><div data-name="7_2" onClick={setBg} className="col border"></div><div  data-name="7_3" onClick={setBg} className="col border"></div><div  data-name="7_4" onClick={setBg} className="col border"></div><div  data-name="7_5" onClick={setBg} className="col border"></div><div  data-name="7_6" onClick={setBg} className="col border"></div><div  data-name="7_7" onClick={setBg} className="col border"></div></div>
          <div className="row "><div className="col border">8</div><div data-name="8_1" onClick={setBg} className="col border"></div><div data-name="8_2" onClick={setBg} className="col border"></div><div  data-name="8_3" onClick={setBg} className="col border"></div><div  data-name="8_4" onClick={setBg} className="col border"></div><div  data-name="8_5" onClick={setBg} className="col border"></div><div  data-name="8_6" onClick={setBg} className="col border"></div><div  data-name="8_7" onClick={setBg} className="col border"></div></div>
          <div className="row "><div className="col border">9</div><div data-name="9_1" onClick={setBg} className="col border"></div><div data-name="9_2" onClick={setBg} className="col border"></div><div  data-name="9_3" onClick={setBg} className="col border"></div><div  data-name="9_4" onClick={setBg} className="col border"></div><div  data-name="9_5" onClick={setBg} className="col border"></div><div  data-name="9_6" onClick={setBg} className="col border"></div><div  data-name="9_7" onClick={setBg} className="col border"></div></div> */}
        </div>
        :null
      }
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" onClick={kaydet}>
          Kaydet
        </Button> */}
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default R1;
