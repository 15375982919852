/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ModalComp from "../components/ModalComp";
import { Modal, Form, Button, Table } from "react-bootstrap";
import {
  BsFillXSquareFill,
  BsFillCheckSquareFill,
  BsFillPencilFill,
} from "react-icons/bs";
import secureLocalStorage from "react-secure-storage";
import { mesaj, TContainer, Ganimated } from "../helpers/generic";
import * as yup from "yup";

const ZamanKurallari = (props) => {
 
  //const localListe = secureLocalStorage.getItem("ZamanKurallari");
  const [getModal, setModal] = useState("");
  //const [data, setData] = useState(localListe ?? []);
  const ModalKapat = () => { 
    setModal(null);
  };
  const ModalAc = (name,modalYazi) => {
    setModal(<ModalComp sendModalKapat={ModalKapat} name={name} modalYazi={modalYazi} />);
  };
 
const ListItem=(props)=>{
  const ls =secureLocalStorage.getItem("ZamanKurallari"+props.modalAdi); 
  return(
    <a href="#" onClick={() => ModalAc(props.modalAdi,props.modalYazi)}  className="list-group-item"  >
      {props.modalYazi}
      {ls ? <BsFillPencilFill color="green"/> : ""}
    </a>
  )
}
return (
    <>
      <div className="card-body bg-light">
        <hr></hr>
        <h1 className="text-center">Zaman Kuralları</h1>
        <hr></hr>
        <div className="row">
          <div className="col-4">
            <h3 className="text-center">Öğretmenler</h3>
            <ul className="list-group">
              <ListItem modalAdi={"R1"} modalYazi={"Öğretmenin uygun olmadığı saatler"}/>
              <ListItem modalAdi={"R2"} modalYazi={"Öğretmenin haftalık maksimum ders günü"}/>
              <ListItem modalAdi={"R3"} modalYazi={"Öğretmenin haftalık minimum ders günü"}/>
              <ListItem modalAdi={"R4"} modalYazi={"Öğretmenin günlük maksimum boşluk sayısı"}/>
              <ListItem modalAdi={"R5"} modalYazi={"Öğretmenin haftalık maksimum boşluk sayısı"}/>
              <ListItem modalAdi={"R6"} modalYazi={"Öğretmenin günlük maksimum ders saati"}/>
              <ListItem modalAdi={"R7"} modalYazi={"Öğretmenin etikete göre arka arkaya maksimum ders saati"}/>
              <ListItem modalAdi={"R8"} modalYazi={"Öğretmenin günlük minimum ders saati"}/>
              <ListItem modalAdi={"R9"} modalYazi={"Öğretmenin arka arkaya maksimum ders saati"}/>
              <ListItem modalAdi={"R10"} modalYazi={"Öğretmenin etikete göre günlük maksimum ders saati"}/>
              <ListItem modalAdi={"R11"} modalYazi={"Öğretmenin saat aralığına göre maksimum gün sayısı"}/>
              <ListItem modalAdi={"R12"} modalYazi={"Tüm öğretmenler için haftalık maksimum ders günü sayısı"}/>
              <ListItem modalAdi={"R13"} modalYazi={"Tüm öğretmenler için haftalık minimum ders günü sayısı"}/>
              <ListItem modalAdi={"R14"} modalYazi={"Tüm öğretmenler için günlük maksimum boşluk sayısı"}/>
              <ListItem modalAdi={"R15"} modalYazi={"Tüm öğretmenler için haftalık maksimum boşluk sayısı"}/>
              <ListItem modalAdi={"R16"} modalYazi={"Tüm öğretmenler için günlük maksimum saati"}/>
              <ListItem modalAdi={"R17"} modalYazi={"Tüm öğretmenler için etikete göre günlük maksimum ders saati"}/>
              <ListItem modalAdi={"R18"} modalYazi={"Tüm öğretmenler için günlük minimum ders saati"}/>
              <ListItem modalAdi={"R19"} modalYazi={"Tüm öğretmenler için arka arkaya maksimum ders saati"}/>
              <ListItem modalAdi={"R20"} modalYazi={"Tüm öğretmenler için etikete göre arka arkaya maksimum ders saati"}/>
              <ListItem modalAdi={"R21"} modalYazi={"Tüm öğretmenler için saat aralığına göre maksimum gün sayısı"}/>
            </ul>
          </div>
          <div className="col-4">
            <h3 className="text-center">Sınıflar</h3>
            <ul className="list-group">
              <ListItem modalAdi={"R101"} modalYazi={"Sınıfın uygun olmadığı saatler"}/>
 
              <a href="#" className="list-group-item">
                Sınıfın haftalık maksimum ders günü{" "}
              </a>
              <a href="#" className="list-group-item">
                Sınıfın günlük maksimum boşluk sayısı{" "}
              </a>
              <a href="#" className="list-group-item">
                Sınıfın haftalık maksimum boşluk sayısı{" "}
              </a>
              <a href="#" className="list-group-item">
                Sınıfın haftalık maksimum geç gelebileceği gün sayısı{" "}
              </a>
              <a href="#" className="list-group-item">
                Sınıfın günlük maksimum ders saati{" "}
              </a>
              <a href="#" className="list-group-item">
                Sınıfın etikete göre günlük maksimum ders saati{" "}
              </a>
              <a href="#" className="list-group-item">
                Sınıfın günlük minimum ders saati{" "}
              </a>
              <a href="#" className="list-group-item">
                Sınıfın arka arkaya maksimum ders saati{" "}
              </a>
              <a href="#" className="list-group-item">
                Sınıfın etikete göre arka arkaya maksimum ders saati{" "}
              </a>
              <a href="#" className="list-group-item">
                Sınıfın saat aralığına göre maksimum gün sayısı{" "}
              </a>
              <a href="#" className="list-group-item">
                Tüm sınıflar için haftalık maksimum ders günü sayısı{" "}
              </a>
              <a href="#" className="list-group-item">
                Tüm sınıflar için günlük maksimum boşluk sayısı{" "}
              </a>
              <a href="#" className="list-group-item">
                Tüm sınıflar için haftalık maksimum boşluk sayısı{" "}
              </a>
              <a href="#" className="list-group-item">
                Tüm sınıflar için haftalık maksimum geç gelebileceği gün sayısı{" "}
              </a>
              <a href="#" className="list-group-item">
                Tüm sınıflar için günlük maksimum ders saati{" "}
              </a>
              <a href="#" className="list-group-item">
                Tüm sınıflar için etikete göre günlük maksimum ders saati{" "}
              </a>
              <a href="#" className="list-group-item">
                Tüm sınıflar için günlük minimum ders saati{" "}
              </a>
              <a href="#" className="list-group-item">
                Tüm sınıflar için arka arkaya maksimum ders saati{" "}
              </a>
              <a href="#" className="list-group-item">
                Tüm sınıflar için etikete göre arka arkaya maksimum ders saati{" "}
              </a>
              <a href="#" className="list-group-item">
                Tüm sınıflar için saat aralığına göre maksimum gün sayısı{" "}
              </a>
            </ul>
          </div>
          <div className="col-4">
            <h3 className="text-center">Dersler</h3>
            <ul className="list-group">
              <a href="#" className="list-group-item">
                Bir dersin tercih edilen başlama saati
              </a>
              <a href="#" className="list-group-item">
                Bir dersin tercih edilen başlama saatleri
              </a>
              <a href="#" className="list-group-item">
                Bir dersin tercih edilen saatleri
              </a>
              <a href="#" className="list-group-item">
                Seçilen dersler için tercih edilen başlama saatleri
              </a>
              <a href="#" className="list-group-item">
                Seçilen derslder için tercih edilen saatler
              </a>
              <a href="#" className="list-group-item">
                Seçilen derslerin arasındaki minimum gün sayısı
              </a>
              <a href="#" className="list-group-item">
                Seçilen derslerin arasındaki maksimum gün sayısı
              </a>
              <a href="#" className="list-group-item">
                Bir ders günün son dersi olsun
              </a>
              <a href="#" className="list-group-item">
                Seçilen dersler günün son dersi olsun
              </a>
              <a href="#" className="list-group-item">
                Seçilen dersler aynı gün ve saatte başlasın
              </a>
              <a href="#" className="list-group-item">
                Seçilen dersler aynı günde olsun (herhangi bir saatte)
              </a>
              <a href="#" className="list-group-item">
                Seçilen dersler aynı saatte başlasın (herhangi bir günde)
              </a>
              <a href="#" className="list-group-item">
                Seçilen derslerin maksimum zaman işgali
              </a>
              <a href="#" className="list-group-item">
                İki ders sıralı
              </a>
              <a href="#" className="list-group-item">
                İki ders ardışık
              </a>
              <a href="#" className="list-group-item">
                İki ders gruplanmış
              </a>
              <a href="#" className="list-group-item">
                Üç ders gruplanmış
              </a>
              <a href="#" className="list-group-item">
                Çakışmayan dersler
              </a>
              <a href="#" className="list-group-item">
                Seçilen zamanlarda aynı andaki maksimum ders sayısı
              </a>
              <a href="#" className="list-group-item">
                Seçilen dersler arasındaki minimum boşluk
              </a>
            </ul>
          </div>
        </div>
        <hr></hr>
        <button className="btn btn-primary">Tüm Zaman Kurallarını Sil</button>
      </div>
      {getModal}
    </>
  );
};
export default ZamanKurallari;
