import React from "react";
import { Link, Route, Routes } from "react-router-dom";

// import Menu from './layouts/Menu';

import Index from "./views/Index";
import Kayit from "./views/Kayit";
import Giris from "./views/Giris";
import CalismaDonemleri from "./views/CalismaDonemleri";
import RaporBilgileri from "./views/RaporBilgileri";
import DersGunleri from "./views/DersGunleri";
import DersSaatleri from "./views/DersSaatleri";
import Branslar from "./views/Branslar";
import Ogretmenler from "./views/Ogretmenler";
import SiniflarVeSubeler from "./views/SiniflarVeSubeler";
import BinalarveDerslikler from "./views/BinalarveDerslikler";
import DersHavuzu from "./views/DersHavuzu";
import DersAtamalari from "./views/DersAtamalari";
import ZamanKurallari from "./views/ZamanKurallari";
import MekanKurallari from "./views/MekanKurallari";
import Sihirbaz from "./views/Sihirbaz";
import SifremiUnuttum from "./views/SifremiUnuttum";
import DersProgramListesi from "./views/DersProgramListesi";
import SideNav from "./layouts/SideNav";
import Header from "./layouts/Header";
// import PageNotFound from './routing/PageNotFound'
import secureLocalStorage from "react-secure-storage";

function App() {
  const Oturum = secureLocalStorage.getItem("Oturum");
  const Version = secureLocalStorage.getItem("Version");
  const MVersion="1.76"
  if (Version != MVersion) {
    secureLocalStorage.clear("Version");
    secureLocalStorage.clear("RaporBilgileri");
    secureLocalStorage.clear("BinalarveDerslikler");
    secureLocalStorage.clear("CalismaDonemleri");
    secureLocalStorage.clear("Ogretmenler");
    secureLocalStorage.clear("DersAtamalari");
    secureLocalStorage.clear("DersHavuzu");
    secureLocalStorage.clear("DersSaatleri");
    secureLocalStorage.clear("Branslar");
    secureLocalStorage.clear("DersGunleri");
    secureLocalStorage.clear("SiniflarveSubeler");
    secureLocalStorage.clear("Oturum");
    secureLocalStorage.setItem("Version",MVersion);
    console.log("Yeni versiyon bulundu.")
  }
  //console.log(Oturum);
  return (
    <>
      {Oturum != null ? (
        <>
          <Header />
          {/* <SideNav /> */}
          <Routes>
            {/* <Menu/> */}
            <Route path="/" element={<DersProgramListesi />} />
            <Route path="/Giris" element={<Giris />} />
            <Route path="/Kayit" element={<Kayit />} />
            <Route path="/SifremiUnuttum" element={<SifremiUnuttum />} />
            <Route path="/DersProgrami" element={<DersProgramListesi />} />
            <Route path="/Sihirbaz" element={<Sihirbaz />} />
            <Route path="*" element={<div className="h-100"><h1>404</h1><br />Sayfa bulunamadı <br /><br /><br /><h2>Lütfen <Link to={"/Giris"}>Giriş</Link> yapın...</h2><br /><br /><br /><br /><br /><br /><br /></div>} />
          </Routes>
        </>
      ) : (
        <>
          <Header />
          {/* <SideNav /> */}
          <center>
            <Routes>
              <Route path="/" element={<Giris />} />
              <Route path="/Giris" element={<Giris />} />
              <Route path="/Kayit" element={<Kayit />} />
              <Route path="/SifremiUnuttum" element={<SifremiUnuttum />} />
              <Route path="*" element={<div className="h-100"><h1>404</h1><br />Sayfa bulunamadı <br /><br /><br /><h2>Lütfen <Link to={"/Giris"}>Giriş</Link> yapın...</h2><br /><br /><br /><br /><br /><br /><br /></div>} />
            </Routes>
          </center>
        </>
      )}
    </>
  );
}

export default App;
